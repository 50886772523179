import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { fetchData } from "../../helpers/databaseHelpers";
import '../../Website/assets/css/pay.css';
let Paynow = () => {
  let baseUrl = "https://satsangirishtey.com:5000";

  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

    if (!token) {
     navigate('/');
    }

    const { amount } = useParams();
    const [plan, setPlans] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [user_id, setUserId] = useState("");
  useEffect(() => {
    const loadDetails = async () => {
      try {
        const whereObj = { token: token };
        const userData = await fetchData("*", "users", whereObj);
        if (userData.length === 0) {
          navigate("/");
        }
        setUserDetails(userData[0]);
        setName(userData[0].first_name + ' ' + userData[0].last_name);
        setNumber(userData[0].phone);
        setUserId(userData[0].uniquecode);
      } catch (error) {
        navigate("/");
      }
    };
    loadDetails();


   
      const loadPlan = async () => {
          try {
              const whereObjj = { 'status':'active','price':amount};
              const meData = await fetchData("*", "plan", whereObjj);
              setPlans(meData[0]);
          } catch (error) {
              console.error("Error loading plan details:", error);
          }
      };
      loadPlan();
 

  }, [token]);



const [name_err,setNameErr] = useState("");
const [number_err,setNumberErr] = useState("");

function payNowValid(){
    let valid = true;
    if(name==''){
        setNameErr('Name is Required!');
        valid = false;
    }

    if(number==''){
        setNumberErr("Phone is Required!");
        valid = false;
    }

    return valid;
}

  const gstRate = 18;
  
  const gstAmount = (amount * gstRate) / 100;
  const totalAmount = parseFloat(amount) + parseFloat(gstAmount);
  let handlePayment = async (event) => {
    event.preventDefault();
       
    if(payNowValid()){
       
        const formData = new FormData();
        formData.append("name", name);
        formData.append("user_id", user_id);
        formData.append("mobileNumber", number);
        formData.append("amount", totalAmount);
        formData.append("plan",plan.name);
        formData.append("no_of_contact",plan.no_of_contact);
        formData.append("amount_before_gst",plan.price);
        formData.append("gst_amount",gstAmount);
        try {
            const response = await fetch(`${baseUrl}/phonepayPayment`, {
              method: "POST",
              body: formData,
            });
    
            const data = await response.json();
            if (response.ok) {
              const payUrl = data.data.data.instrumentResponse.redirectInfo.url;
              window.location.href = payUrl;
              setName("");
              setNumber("");
            } else {
              toastr.error(data.message);
            }
          } catch (error) {
            toastr.error("An error occurred. Please try again later.");
          }
    }
  };

  return (
    <>
      <section className="w90 contactus paynow fcs-display m_auto">
        
      
        <div className="details">
        <div className="detail-row">
          <span className="label">Amount:</span>
          <span className="value">&#8377;{amount}</span>
        </div>

        <div className="detail-row">
          <span className="label">GST ({gstRate}%) :</span>
          <span className="value">&nbsp;&nbsp;&#8377;{gstAmount}</span>
        </div>

        <hr className="divider" />

        <div className="detail-row total">
          <span className="label">Total:</span>
          <span className="value">&#8377;{totalAmount}</span>
        </div>
      </div>

        <div className=" w50">
          <form
            action="javascript:void(0)"
            method="post"
            id="payNowForm"
            onSubmit={handlePayment}
          >
            <div className="card_bx">
              <label htmlFor="Name">
                <i className="bi bi-person-fill"></i>Name
              </label>

              <input
                type="text"
                placeholder="Name"
                name="Name"
                id="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameErr("");
                }}
              />
              <div className="err">{name_err}</div>
            </div>
            <div className="card_bx">
              <label htmlFor="Phone">
                <i className="bi bi-phone-fill"></i>Number
              </label>
              <input
                type="text"
                placeholder="Phone"
                name="Phone"
                id="number"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                  setNumberErr("");
                }}
              />

              <div className="err">{number_err}</div>
            </div>
          
            <div className="card_bx">
              <button className="pay_now" type="submit">
                Pay Now
              </button>
            </div>
          </form>
        </div>
        <div
          className="right_job_bx w50 fcc-display"
          style={{ margin: "auto" }}
        >
          <img src="icon/illu_8.png" alt="" style={{ width: "80%" }} />
        </div>
      </section>
    </>
  );
};

export default Paynow;
