import React from 'react';
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  let navigate = useNavigate();
  return (
    <div style={{ margin: '0 auto', paddingTop: '105px' }} className='w80 m-auto'>

      <div className="pra_detai w80 m_auto">
        <p>We're a simple and easy to use matrimonial website through which you can easily search your satsangi life partner following the same path.
        </p>

        <p>We've made this website specifically for spiritual people only who are connected and following any spiritual path of their choice. This website offers you profiles from more than 17 spiritual paths from all across the world.
        </p>

        <p>If you're also following a spiritual path and looking for a life partner following the same spiritual path then you just need to register yourself, create your profile and you'll be able to search and make matches with spiritual partner following the same path of your choice.
        </p>

        <p>
          This is our small attempt to bring the spiritual people on this platform and let the people choose the life partner of their choice in their very own spiritual community.
        </p>

        <p>We’ve given multiple filter options to let you choose the right person. You may consult with our relationship managers and they’ll guide you to increase your match making chances.</p>
      </div>

      <div className="register_band register_band3 w80 fcs-display">
        <h1 className='head'>Your Spiritual Journey can become very easy with a life partner following the same path</h1>
      </div>


      <div className="pra_detai w80 m_auto">
        <p>As per the spiritual concepts, if life partners are on the same spiritual path and following the same rules and rituals then their spiritual journey becomes very easy and it’s easier for them to achieve great heights in spirituality also.
        </p>

        <p>Marrying a life partner who is on the same spiritual path makes your life very easy and convenient. Make your profile and search your satsangi life partner on our website with ease.
        </p>
      </div>

      <div className="about_card_sec w80 fcs-display m_auto" style={{marginTop: '30px'}}>
        <div className="left_about_bx">
          <img src="img/bg3.jpg" alt="" />
        </div>
        <div className="right_about_bx">
          <p>A life partner following the same spiritual path as yours will be:</p>
          <p>·Familiar with the customs and rituals of your path.</p>
          <p>·Will be having the same life style and eating habits like yours.</p>
          <p>·Will be having the same spiritual events and venue to attend.</p>
          <p>·Will be easier to understand and make a strong bond.</p>
          <p>·Will be having the same thought process as yours.</p>
        </div>
      </div>


      <div className="register_band w80 fcs-display">
        <h1 className='head'>Your Satsangi Life Partner is Waiting for you</h1>
        <button className='btn_reset' onClick={() => {
          navigate('/plan');
        }}>Upgrade Now</button>
      </div>
      
    </div>
  );
};

export default AboutUs;
