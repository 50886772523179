import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import { fetchData } from "../../helpers/databaseHelpers";

let Received = () => {
  let baseUrl = 'https://satsangirishtey.com:5000';
  const [userDetails, setUserDetails] = useState([]);
  const [filterChange, setFilterChange] = useState(false);

  const [viwed, setViwed] = useState("all"); // 'all' as the default selected option
  const [MatchesData, setMatchesData] = useState([]);

  let navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  if (!token) {
    console.log("No token found. Redirecting to login.");
    navigate("/");
  }

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const whereObj = { token: token };
        const userData = await fetchData("*", "users", whereObj);
        setUserDetails(userData[0]);
      } catch (error) {
        console.error("Error loading user details:", error);
      }
    };
    loadDetails();
  }, [token]);

  useEffect(() => {
    const loadInbox = async () => {
      try {
        let user_id = userDetails.uniquecode;
        let type = "received";
        let filterData = {
          viwed,
        };
        const response = await fetch(`${baseUrl}/fetchInbox`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id, type, filterData }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const receivedMatches = await response.json();
        setMatchesData(receivedMatches.data);
      } catch (error) {
        console.error("Error loading new matches:", error);
      }
    };

    if (filterChange) {
      loadInbox();
      setFilterChange(false); // Reset filterChange after loading matches
    } else {
      loadInbox();
    }
  }, [userDetails.uniquecode, filterChange]);


  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const changeDate = (dateStr) =>{
    let dateObj = new Date(dateStr);
    let day = dateObj.getUTCDate();
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = monthNames[dateObj.getUTCMonth()];
    let formattedDate = `${day} ${month}`;
    return formattedDate;
  }

  const  likeProfile = async (receiver_id,sender_id)=>{
    try {
      const response = await fetch(`${baseUrl}/likeProfile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ receiver_id, sender_id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if(data.status){
        setFilterChange(true);
        toastr.success(data.message);
      }else{
        toastr.error(data.message);
      }
    } catch (error) {
      console.error("Error loading new matches:", error);
    }
  }

  const  cancelProfile = async (receiver_id,sender_id)=>{
    try {
      const response = await fetch(`${baseUrl}/cancelProfile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ receiver_id, sender_id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if(data.status){
        setFilterChange(true);
        toastr.success(data.message);
      }else{
        toastr.error(data.message);
      }
    } catch (error) {
      console.error("Error loading new matches:", error);
    }
  }
  return (
    <>
      <section className="w70 new_matches inbox inbox_recevied fcs-display m_auto">
        <div className="match_left_bx" style={{ marginTop: "50px" }}>
          <div className="filter">
          <div className="profile ad_p fcc-display w100">
          <Link to='/plan'> <img src="img/contact_call.jpg" alt=""  /></Link>
            </div>
          </div>
        </div>
        <div className="match_right_bx">
          <h3 className="title">Received Invitations</h3>

          {MatchesData.length > 0 ? (
            MatchesData.map((el, i) => (
              <div className="card_matches w-100 fcs-display">
                <div className="img_bx">
                  <img src={el.profile_pic} alt="profile pic" />
                </div>
                <div className="content_bx">
                  <h4>
                    {el.first_name} {el.last_name}{" "}
                    <i className="bi bi-shield-check"></i>
                  </h4>
                  <div className="cont_head w-100 fcs-display">
                    <h6>
                      <i className="bi bi-chat-right-dots-fill"></i> Online 3h
                      ago
                    </h6>
                    <div className="r_fav">
                        <span>{changeDate(el.date)}</span>
                    </div>
                  </div>
                  <ul className="fcs-display w-100">
                    <li>
                      {el.age ? el.age : calculateAge(el.d_o_b)} yrs,{" "}
                      {el.height}
                    </li>
                    <li>{el.community}</li>
                    <li>
                      {el.country}, {el.state}
                    </li>
                    <li>{el.marital_status}</li>
                    <li>{el.mother_tongue}</li>
                    <li>{el.religion}</li>
                  </ul>

                  {(userDetails.account_type=='Pro') ? (<p>{el.about}<Link to={`/user/${el.uniquecode}`}>Show more.</Link></p>):( <div className="upgrade_bx_card">
                    <i className="bi bi-envelope-fill"></i>
                    <h6>
                      <i className="bi bi-lock-fill"></i> Upgrade to see all details.
                    </h6>
                    <Link to="#">Upgrade</Link>
                  </div>) }
                  
                </div>
                <div className="connect_bx">
                  <div className="connect_profile w-100 fcc-display">
                    <h6>Like this profile ?</h6>
                    <i className="bi bi-check-circle-fill"  onClick={() => likeProfile(userDetails.uniquecode,el.uniquecode)}></i>

                    <p>Connect Now</p>
                    <i className="bi bi-x" onClick={() => cancelProfile(userDetails.uniquecode,el.uniquecode)}></i>
                    <p>Cancel</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No recent visitors found.</p>
          )}
        </div>
      </section>
    </>
  );
};

export default Received;
