import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import {
    fetchCommunity,
    fetchIncomeRange,
    fetchMaritalStatus,
    fetchData
  } from "../../helpers/databaseHelpers";

let MyMatches = () => {
    let baseUrl = 'https://satsangirishtey.com:5000';
    const [userDetails, setUserDetails] = useState([]);
    const [filterChange, setFilterChange] = useState(false);
    const [partnerPreference, setPartnerPreference] = useState([]);
    const [communityList, setCommunityList] = useState([]);
    const [IncomeList, setIncomeList] = useState([]);
    const [MaritalList, setMaritalList] = useState([]);
    const [state, setState] = useState('all');
    const [country, setCountry] = useState('all');
    const [recently_joined, setRecentlyJoined] = useState('all');
    const [active_members, setActiveMembers] = useState('all');
    const [anaual_income, setAunalIncome] = useState('all');
    const [marital_status, setMaritalStatus] = useState('all');
    const [spritual_path, setSpritualPath] = useState('all');
    const [MatchesData, setMatchesData] = useState([]);
    const [favData, setFavData] = useState([]);
    let navigate = useNavigate();
    const token = localStorage.getItem("authToken");

    if (!token) {
        console.log("No token found. Redirecting to login.");
        navigate("/");
    }

    useEffect(() => {
        const loadDetails = async () => {
            try {
                const whereObj = { token: token };
                const userData = await fetchData("*", "users", whereObj);
                setUserDetails(userData[0]);
            } catch (error) {
                console.error("Error loading user details:", error);
            }
        };
        loadDetails();
    }, [token]);

    useEffect(() => {
        const loadpref = async () => {
            try {
                if (userDetails.uniquecode) {
                    let user_id = userDetails.uniquecode;
                    const whereObj1 = { user_id: user_id };
                    const pref = await fetchData("*", "partner_preferences", whereObj1);
                    if (pref) {
                        setPartnerPreference(pref[0]);
                    }
                }
            } catch (error) {
                console.error("Error loading preferences:", error);
            }
        };
        loadpref();

        const loadCommunity = async () => {
            try {
                const communities = await fetchCommunity();
                setCommunityList(communities);
            } catch (error) {
                console.error("Error loading community:", error);
            }
        };
        loadCommunity();

        const loadIncome = async () => {
            try {
                const incomes = await fetchIncomeRange();
                setIncomeList(incomes);
            } catch (error) {
                console.error("Error loading income:", error);
            }
        };
        loadIncome();

        const loadMarital = async () => {
            try {
                const martial = await fetchMaritalStatus();
                setMaritalList(martial);
            } catch (error) {
                console.error("Error loading marital status:", error);
            }
        };
        loadMarital();

        const loadProfileData = async (user_id, activity_type) => {
            try {
              const response = await fetch(`${baseUrl}/getProfileData`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id, activity_type }),
              });
          
              const result = await response.json();
          
              if (result.status) {
                setFavData(result.data);
              } 
            } catch (error) {
              console.error("Fetch error:", error);
             
            }
          };
          
          loadProfileData(userDetails.uniquecode, 'shortlisted');

        const loadNewMatches = async () => {
            try {
                let user_id = userDetails.uniquecode;
                let filterData = {
                    spritual_path,
                    marital_status,
                    annual_income: anaual_income,
                    country,
                    state
                };
                const response = await fetch(`${baseUrl}/fetchSuggestedMatches`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id, filterData }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const newMatches = await response.json();
                setMatchesData(newMatches.data);
            } catch (error) {
                console.error('Error loading new matches:', error);
            }
        };

        if (filterChange) {
            loadNewMatches();
            setFilterChange(false); // Reset filterChange after loading matches
        }else{
            loadNewMatches();
        }

    }, [userDetails.uniquecode, filterChange]);

    const handleOptionChange = (setter) => (event) => {
        setter(event.target.value);
        setFilterChange(true);
    };

    const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const [isConnected, setIsConnected] = useState(false);

    const handleConnectNow = () => {
        setIsConnected(true);
    };


    useEffect(() => {

        Array.from(document.getElementsByClassName('filter_head')).forEach((el, i) => {
            el.addEventListener('click', () => {
                const icon = el.getElementsByTagName('i')[0];
                const radioSection = document.getElementsByClassName('radio_section')[i];
              
              if (icon.classList.contains('bi-plus-square-fill')) {
                  icon.classList.remove('bi-plus-square-fill');
                  icon.classList.add('bi-dash-square-fill');
                  radioSection.classList.add('radio_sec_active');
                  
              } else {
                  icon.classList.remove('bi-dash-square-fill');
                  icon.classList.add('bi-plus-square-fill');
                  radioSection.classList.remove('radio_sec_active');
               
              }
          });
      });
  
  }, [])


  let [height , setHeight] = useState('0');

    useEffect(() => {
        setHeight(document.getElementsByClassName('filter')[0].clientHeight);
    }, [userDetails.uniquecode,filterChange])


    const HandleFav = async (user_id,favUserId) => {
        let _url = `${baseUrl}/addToFav`;
    
        try {
          const response = await fetch(_url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id, favUserId }),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            setFilterChange(true);
            toastr.success(data.message || "Added to Favorite.");
          } else {
            toastr.error(data.message || "Error in Adding.");
          }
        } catch (error) {
          toastr.error(error.message || "Error in Adding.");
        }
    };

    return (
        <>
            <section className="w70 new_matches fcs-display m_auto">
                <div className="match_left_bx">
                    <div className="filter">
                        <h3><i className="bi bi-filter-circle-fill"></i> Filter</h3>
                        {/* <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Recently Joined</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={recently_joined === 'all'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinDay"
                                        checked={recently_joined === 'withinDay'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a day (152)</label>
                                </div>

                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinWeek"
                                        checked={recently_joined === 'withinWeek'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a week (500+)</label>

                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinMonth"
                                        checked={recently_joined === 'withinMonth'}
                                        onChange={handleOptionChange_rj}
                                    />
                                    <label>Within a month (2000+)</label>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Active Members</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={active_members === 'all'}
                                        onChange={handleOptionChange_am}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinaMonth"
                                        checked={active_members === 'withinaMonth'}
                                        onChange={handleOptionChange_am}
                                    />
                                    <label>Within a month (52)</label>
                                </div>
                            </div>
                        </div> */}
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Spirtual Path</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={spritual_path === 'all'}
                                        onChange={handleOptionChange(setSpritualPath)}
                                    />
                                    <label>All</label>
                                </div>
                                {
                                   (partnerPreference.community)? 
                                   partnerPreference.community.split(",").map((el, i) => (
                                        <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el}
                                                   checked={spritual_path === el}
                                                onChange={handleOptionChange(setSpritualPath)}
                                            />
                                            <label>{el}</label>
                                        </div>
                                    )) :communityList.map((el, i) => (
                                        <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el.name}
                                                   checked={spritual_path === el.name}
                                                onChange={handleOptionChange(setSpritualPath)}
                                            />
                                            <label>{el.name}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Marital Status</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={marital_status === 'all'}
                                        onChange={handleOptionChange(setMaritalStatus)}
                                    />
                                    <label>All</label>
                                </div>
                                {
                                    partnerPreference.marital_status ? (partnerPreference.marital_status.split(",").map((el, i) => (
                                        <div className="radio_card">
                                        <input
                                            type="checkbox"
                                            value={el}
                                            checked={marital_status === el}
                                            onChange={handleOptionChange(setMaritalStatus)}
                                        />
                                        <label>{el}</label>
                                    </div>
    
                                    ))):(MaritalList.map((el, i) => (
                                        <div className="radio_card">
                                        <input
                                            type="checkbox"
                                            value={el.name}
                                            checked={marital_status === el.name}
                                            onChange={handleOptionChange(setMaritalStatus)}
                                        />
                                        <label>{el.name}</label>
                                    </div>
    
                                    )))
                                    
                                }
                               
                            </div>
                        </div>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Annual Income</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>

                           

                            <div className="radio_section">
                                <div className="radio_card">
                                        <input
                                            type="checkbox"
                                            value="all"
                                            checked={anaual_income === 'all'}
                                            onChange={handleOptionChange(setAunalIncome)}
                                        />
                                        <label>All</label>
                                    </div>

                                    {
                                        partnerPreference.annual_income ? (partnerPreference.annual_income.split(",").map((el, i) => (
                                            <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el}
                                                checked={anaual_income === el}
                                                onChange={handleOptionChange(setAunalIncome)}
                                            />
                                            <label>{el}</label>
                                        </div>
        
                                        ))):(IncomeList.map((el, i) => (
                                            <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el.lable}
                                                checked={anaual_income === el.lable}
                                                onChange={handleOptionChange(setAunalIncome)}
                                            />
                                            <label>{el.lable}</label>
                                        </div>
        
                                        )))
                                    
                                }
                            </div>
                        </div>

                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Country</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>

                           

                            <div className="radio_section">
                                <div className="radio_card">
                                        <input
                                            type="checkbox"
                                            value="all"
                                            checked={country === 'all'}
                                            onChange={handleOptionChange(setCountry)}
                                        />
                                        <label>All</label>
                                    </div>

                                    {
                                        partnerPreference.country ? (partnerPreference.country.split(",").map((el, i) => (
                                            <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el}
                                                checked={country === el}
                                                onChange={handleOptionChange(setCountry)}
                                            />
                                            <label>{el}</label>
                                        </div>
        
                                        ))):('')
                                    
                                }
                            </div>
                        </div>

                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>State</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>

                           

                            <div className="radio_section">
                                <div className="radio_card">
                                        <input
                                            type="checkbox"
                                            value="all"
                                            checked={state === 'all'}
                                            onChange={handleOptionChange(setState)}
                                        />
                                        <label>All</label>
                                    </div>

                                    {
                                        partnerPreference.state ? (partnerPreference.state.split(",").map((el, i) => (
                                            <div className="radio_card">
                                            <input
                                                type="checkbox"
                                                value={el}
                                                checked={state === el}
                                                onChange={handleOptionChange(setState)}
                                            />
                                            <label>{el}</label>
                                        </div>
        
                                        ))):('')
                                    
                                }
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
                <div className="match_right_bx" style={{height: `${height}px`}}>
                { MatchesData.length > 0 ? (
                  MatchesData.map((el, i) => (
                        <div className="card_matches w-100 fcs-display">
                            <div className="img_bx">
                                <img src={el.profile_pic} alt="profile pic" />
                            </div>
                            <div className="content_bx">
                                <h4>{el.first_name} {el.last_name} <i className="bi bi-shield-check"></i></h4>
                                <div className="cont_head w-100 fcs-display">
                                    <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                    <div 
                                        className="r_fav" 
                                        onClick={() => HandleFav(userDetails.uniquecode, el.uniquecode)}>
                                        
                                        <span style={{ marginRight: '50px', position: 'relative', top: '-15px' }}>
                                            {favData.find(item => item.uniquecode ===  el.uniquecode) ? '' : 'Add Favorite'}
                                        </span>
                                        
                                        <i className="bi bi-heart-fill" style={{color:`${favData.find(item => item.uniquecode ===  el.uniquecode) ? '#a01c22' : '#f6aab2'}`}} ></i>
                                    </div>
                                </div>
                                <ul className="fcs-display w-100">
                                    <li>{(el.age)? el.age : calculateAge(el.d_o_b)} yrs, {el.height}</li>
                                    <li>{el.community}</li>
                                    <li>{el.country}, {el.state}</li>
                                    <li>{el.marital_status}</li>
                                    <li>{el.mother_tongue}</li>
                                    <li>{el.religion}</li>
                                </ul>
                                <p>{el.about}<Link to={`/user/${el.uniquecode}`}>Show more.</Link></p>
                            </div>
                            <div className="connect_bx">
                                {
                                    (userDetails.account_type=='pro')?(<div className="connected_profile w-100 fcc-display" >
                                        
                                        <button><i className="bi bi-telephone-fill"></i> Call</button>
                                        <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                        <button><i className="bi bi-chat-right-dots-fill"></i> Chat</button>
                                    </div>):(
                                        !isConnected ? (
                                        <div className="connect_profile w-100 fcc-display connectNow" onClick={handleConnectNow}>
                                            <h6>Like this profile?</h6>
                                            <i className="bi bi-check-circle-fill"></i>
                                            <p>Connect Now</p>
                                        </div>
                                    ) : (
                                        <div className="connected_profile w-100 fcc-display">
                                            <p><Link to='#'>Upgrade</Link> to Contact {el.gender === 'male' ? 'him' : 'her'} directly</p>
                                            <button ><i className="bi bi-telephone-fill"></i> Call</button>
                                            <button ><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                            <button ><i className="bi bi-chat-right-dots-fill"></i> Chat</button>
                                        </div>
                                    ))
                                }
                                
                                
                            </div>
                        </div>
                    ))
                    ) : (
                    <p>No recent visitors found.</p>
                    )}
                </div>
            </section>
        </>
    )
}

export default MyMatches;