import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData, updateData } from "../../helpers/databaseHelpers";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import '../../Website/assets/css/status.css';

const Status = () => {
  const baseUrl = "https://satsangirishtey.com:5000";
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const { txnid } = useParams();

  const [userDetails, setUserDetails] = useState({});
  const [paymentDetail, setPaymentDetails] = useState({});
  const [planDetail, setPlanDetails] = useState({});
  const [refresh, setRefresh] = useState(false);

  // Redirect if no token
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);


  function formatDate(dateString) {
    // Create a new Date object from the input date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    // Extract the day, month, and year
    const day = date.getDate();
    const year = date.getFullYear();

    // Array of month names to convert the month number to the name
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const month = monthNames[date.getMonth()]; // getMonth() returns month index (0-11)

    // Return the formatted date
    return `${day}, ${month} ${year}`;
}

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const userData = await fetchData("*", "users", { token });
        const user_data = userData[0];

        if(user_data && txnid){
          const paymentData = await fetchData("*", "phonepaypayment", { merchantTransactionId: txnid });
          const payment_data = paymentData[0];
          payment_data.date = payment_data.date.split('T')[0];

          setPaymentDetails(payment_data);
          if(payment_data){
            const planData = await fetchData("*", "plan", { name: payment_data.plan });
            const plan_data = planData[0];
            const formData = new FormData();
            formData.append("merchantTransactionId", txnid);
            try {
              const response = await fetch(`${baseUrl}/phonepayCheckStatus`, {
                method: "POST",
                body: formData,
              });
        
              const data = await response.json();
              
              if (data.error) {
                toastr.error(data.error);
                document.getElementById('failedDiv').style.display = 'unset';
                document.getElementById('successDiv').style.display = 'none';
                return;
              }
        
              if (data.ok) {
                const whereObj = { uniquecode: user_data.uniquecode };
                const planBuyDate = payment_data.date;
                const planDuration = plan_data.duration_month;
                if (typeof planBuyDate === "string" && planBuyDate.includes("/")) {
                  const [day, month, year] = planBuyDate.split("/");
                  planBuyDate = `${year}-${month}-${day}`;
                }
                
                let expiryDate = new Date(planBuyDate);
                let planExpiryDate = ''; 
                expiryDate.setMonth(expiryDate.getMonth() + planDuration);
                planExpiryDate = expiryDate.toISOString().split('T')[0];
                
                const updateDataArr = {
                  plan: plan_data.name,
                  plan_buy_date: planBuyDate,
                  plan_expiry_date: planExpiryDate,
                  no_of_contact: plan_data.no_of_contact,
                  no_of_message: plan_data.no_of_message,
                  primium_badge: plan_data.primium_badge,
                  relationship_manager: plan_data.relationship_manager,
                  nri_rishtey: plan_data.nri_rishtey,
                  vip_treatment: plan_data.vip_treatment,
                  payment_txn_id: txnid,
                  account_type: 'Pro',
                };
               
                await updateData("users", updateDataArr, whereObj);
        
                // Update UI after success
                document.getElementById('failedDiv').style.display = 'none';
                document.getElementById('successDiv').style.display = 'unset';
              }
            } catch (error) {
              console.log(error);
              document.getElementById('failedDiv').style.display = 'unset';
              document.getElementById('successDiv').style.display = 'none';
            }
          }
          
        }
       
      } catch (error) {
        console.error("Error loading user details:", error);
        toastr.error("An error occurred while loading user details.");
        navigate('/');
      }
    };
  
    loadDetails();
  }, [token,refresh]);


  const handleRetryClick = () => {
    navigate(-2);
  };


  return (
    <section className="w90 paynow fcs-display m_auto">
      <div className="payment-failed-container" id="failedDiv" style={{ display: 'none' }}>
        <div className="header">
          <strong>Oops! You are just one step away from finding your soulmate.</strong>
        </div>
        <h2><i className="bi bi-exclamation-circle-fill"></i> Payment failed</h2>
        <p>Hey, it seems there was some trouble.</p>
        <p>Don't worry, we’re there for you. Just hold back.</p>
        <p>You can click on the below button and re-attempt for the payment. You may contact our marriage counsellor for any support required for the payment.</p>
        <button className="retry-button" onClick={handleRetryClick}>
          Retry Payment
        </button>
      </div>

      <div className="payment-confirmation-container" id="successDiv" style={{ display: 'none' }}>
        <div className="header">
          <strong>Congratulations! You are one more step closer to finding your soulmate.</strong>
        </div>
        <div className="confirmation-body">
          <div className="confirmation-left">
            <h2 className="confirmed"><i className="bi bi-check2-circle"></i> Payment Confirmed</h2>
            <p className="thankucl">Thank you for your payment.</p>
            <p className="thankucl">Your subscribed plan will be activated, and you will receive an order confirmation email shortly on your registered mail ID.</p>
          </div>
          <div className="confirmation-right">
            <table style={{ textAlign: "justify" }}>
              <tbody>
                <tr>
                  <td>Transaction ID:&nbsp;&nbsp;</td>
                  <td>{paymentDetail.merchantTransactionId}</td>
                </tr>
                <tr>
                  <td>Date:&nbsp;&nbsp;</td>
                  <td>{formatDate(paymentDetail.date)}</td>
                </tr>
                <tr>
                  <td>Amount:&nbsp;&nbsp;</td>
                  <td>Rs {paymentDetail.amount_before_gst}</td>
                </tr>
                <tr>
                  <td>GST@18%:&nbsp;&nbsp;</td>
                  <td>Rs {paymentDetail.gst_amount}</td>
                </tr>
                <tr>
                  <td>Total:&nbsp;&nbsp;</td>
                  <td>Rs {paymentDetail.amount}</td>
                </tr>
                <tr>
                  <td>Subscription:&nbsp;&nbsp;</td>
                  <td>{paymentDetail.plan}</td>
                </tr>
                <tr>
                  <td>Contacts:&nbsp;&nbsp;</td>
                  <td>{paymentDetail.no_of_contact}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button className="back-button" onClick={() => navigate('/myprofile')}>
          Back to My Profile
        </button>
      </div>
    </section>
  );
};

export default Status;