import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import {
  fetchProfiles,
  fetchCommunity,
  fetchReligion,
  fetchIncomeRange,
  fetchQualification,
  fetchCountry,
  fetchState,
  fetchCity,
  fetchMaritalStatus,
  fetchMotherTongue,
  fetchHeight,
  fetchStateMultiple,
  fetchCityMultiple,
  fetchData,
} from "../../helpers/databaseHelpers";

let AdvancedSearch = () => {
  let baseUrl = 'https://satsangirishtey.com:5000';
  const [userDetails, setUserDetails] = useState([]);
  const [IncomeRange, setIncomeRange] = useState([]);
  const [communityes, setCommunityes] = useState([]);
  const [MaritalStatus, setMaritalStatus] = useState([]);
  const [ReligonStatus, setReligonStatus] = useState([]);
  const [ToungeStatus, setTounge] = useState([]);
  const [CountryStatus, setCountryStatus] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [QualificationList, setQualificationList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [hobbiesList, setHobbiesList] = useState([]);
  const [height, setHeight] = useState([]);
  const [startHeight, setStartHeight] = useState("");
  const [endHeight, setEndHeight] = useState("");

  let navigate = useNavigate();
  const token = localStorage.getItem("authToken");

  if (!token) {
    console.log("No token found. Redirecting to login.");
    navigate("/");
  }

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const whereObj = { token: token };
        const userData = await fetchData("*", "users", whereObj);
        const user = userData[0];
        setUserDetails(user);
        const whereObj1 = { uniquecode: user.uniquecode };
        const searchData = await fetchData("*", "user_search", whereObj1);
        const searchRow = searchData[0];
        if (searchRow.community) {
          const communitySet = searchRow.community
            .split(",")
            .map((com) => ({ label: com, value: com }));
          setSpritiualPath(communitySet);
        }

        SetStart_age(searchRow.start_age);
        SetEnd_age(searchRow.end_age);
        setStartHeight(searchRow.start_height);
        setEndHeight(searchRow.end_height);

        if (searchRow.marital_status) {
          const maritalSet = searchRow.marital_status
            .split(",")
            .map((m1) => ({ label: m1, value: m1 }));
          setMarital(maritalSet);
        }

        setVisibility(searchRow.kids);

        if (searchRow.religion) {
          const religionSet = searchRow.religion
            .split(",")
            .map((m2) => ({ label: m2, value: m2 }));
          setReligion(religionSet);
        }

        if (searchRow.mother_tongue) {
          const TongueSet = searchRow.mother_tongue
            .split(",")
            .map((m3) => ({ label: m3, value: m3 }));
          setToungess(TongueSet);
        }

        if (searchRow.country) {
          const CountrySet = searchRow.country
            .split(",")
            .map((m4) => ({ label: m4, value: m4 }));
          setCountry(CountrySet);
        }

        if (searchRow.state) {
          const StateSet = searchRow.state
            .split(",")
            .map((m5) => ({ label: m5, value: m5 }));
          setState(StateSet);
        }

        if (searchRow.city) {
          const CitySet = searchRow.city
            .split(",")
            .map((m6) => ({ label: m6, value: m6 }));
          setCity(CitySet);
        }

        if(searchRow.path_since){
          setPathSinceVisiblity(searchRow.path_since)
        }

        if(searchRow.initiated){
          setInitiatedVisibility(searchRow.initiated)
        }

        if(searchRow.official_sevadar){
          setOfficialSevadarVisibility(searchRow.official_sevadar)
        }

        if (searchRow.qualification) {
          const qualificationSet = searchRow.qualification
            .split(",")
            .map((m8) => ({ label: m8, value: m8 }));
          setQualification(qualificationSet);
        }

        if (searchRow.income) {
          setVisibility_income("1");
          setIncome(searchRow.income)
        }

        if (searchRow.family_income) {
          setVisibility_income_family("1");
          setIncomeFamily(searchRow.family_income)
        }
        if (searchRow.hobbies) {
          const hobbiesSet = searchRow.hobbies
            .split(",")
            .map((m9) => ({ label: m9, value: m9 }));
          setHobby(hobbiesSet);
        }

      } catch (error) {
        console.error("Error loading user details:", error);
      }
    };
    loadDetails();
  }, [token]);

  useEffect(() => {
    const loadCommunity = async () => {
      try {
        const communityData = await fetchCommunity();
        const communityess = [];
        communityData.map((comu) => {
          communityess.push({ label: comu.name, value: comu.name });
        });
        setCommunityes(communityess);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadCommunity();

    const loadHeight = async () => {
      try {
        const heightData = await fetchHeight();
        setHeight(heightData);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadHeight();

    const loadMarital = async () => {
      try {
        const maritalData = await fetchMaritalStatus();
        const maritalll = [];
        maritalData.map((comu) => {
          maritalll.push({ label: comu.name, value: comu.name });
        });
        setMaritalStatus(maritalll);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadMarital();

    const loadReligion = async () => {
      try {
        const religionData = await fetchReligion();
        const religionn = [];
        religionData.map((comu) => {
          religionn.push({ label: comu.name, value: comu.name });
        });
        setReligonStatus(religionn);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadReligion();

    const loadTounge = async () => {
      try {
        const toungeData = await fetchMotherTongue();
        const toungee = [];
        toungeData.map((comu) => {
          toungee.push({ label: comu.name, value: comu.name });
        });
        setTounge(toungee);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadTounge();

    const loadCountry = async () => {
      try {
        const countryData = await fetchCountry();
        const countryy = [];
        countryData.map((comu) => {
          countryy.push({ label: comu.country, value: comu.country });
        });
        setCountryStatus(countryy);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadCountry();

    const loadIncome = async () => {
      try {
        const incomeData = await fetchIncomeRange();
        const incomerangee = [];
        incomeData.map((comu) => {
          incomerangee.push(comu);
        });
        setIncomeRange(incomerangee);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadIncome();

    const loadQualificaiont = async () => {
      try {
        const qualificaionData = await fetchQualification();

        const qualifications = [];
        qualificaionData.map((qualifi) => {
          qualifications.push({ label: qualifi.name, value: qualifi.name });
        });
        setQualificationList(qualifications);
      } catch (err) {
        console.error("Error loading qualification:", err);
      }
    };

    loadQualificaiont();

    const loadHobiesList = async () => {
      try {
        const listData = await fetchData("*", "hobbies_intrest", {
          status: "active",
        });
        let hob_mul = [];
        listData.map((hob) => {
          hob_mul.push({ label: hob.name, value: hob.name });
        });
        setHobbiesList(hob_mul);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadHobiesList();
  }, []);

  const loadState = async (country) => {
    try {
      const stateData = await fetchStateMultiple(country);
      const _stateNames = [];
      stateData.map((st) => {
        _stateNames.push({ label: st.state, value: st.state });
      });
      setStateList(_stateNames);
    } catch (err) {
      console.error("Error loading state:", err);
    }
  };

  const loadCity = async (country, state) => {
    try {
      const cityData = await fetchCityMultiple(country, state);
      const cityNames = cityData.map((cit) => {
        return { label: cit.city, value: cit.city };
      });
      setCityList(cityNames);
    } catch (err) {
      console.error("Error loading city:", err);
    }
  };

  const [SpritiualPath, setSpritiualPath] = useState([]);
  const [Marital, setMarital] = useState([]);
  const [Religion, setReligion] = useState([]);
  const [Toungess, setToungess] = useState([]);
  const [Country, setCountry] = useState([]);
  const [Income, setIncome] = useState([]);
  const [IncomeFamily, setIncomeFamily] = useState([]);
  const [hobby, setHobby] = useState([]);
  const [State, setState] = useState([]);
  const [City, setCity] = useState([]);
  const [qualification, setQualification] = useState([]);

  const handleChange = (SpritiualPath) => {
    setSpritiualPath(SpritiualPath);
  };

  const handleChangeMarital = (Marital) => {
    setMarital(Marital);
  };

  const handleChangeReligion = (Religion) => {
    setReligion(Religion);
  };

  const handleChangeTounge = (Tounge) => {
    setToungess(Tounge);
  };

  const handleChangeCountry = (Country) => {
    setCountry(Country);
    setState([]);
    loadState(Country);
    setCity([]);
  };

  const handleChangeState = (State) => {
    setState(State);
    loadCity(Country, State);
  };

  const handleChangeCity = (City) => {
    setCity(City);
  };

  const handleQualification = (Qualification) => {
    setQualification(Qualification);
  };

  const handlehobbiesList = (hobby) => {
    setHobby(hobby);
  };

  let [Start_age, SetStart_age] = useState("");
  let [End_age, SetEnd_age] = useState("");

  const handleChangeAge = (e, input) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const age = parseInt(value, 10);

      if (input == "start") {
        SetStart_age(value);
      } else {
        SetEnd_age(value);
      }
    }
  };

  function handelFoucs(id) {
    document.getElementById(id).classList.add("drop_bx_active");
  }

  function handelFoucsout(id) {
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        document.getElementById(id).classList.remove("drop_bx_active");
      }, 300);
    }
  }

  const [visibility, setVisibility] = useState("");

  const handleChangeChildern = (event) => {
    setVisibility(event.target.value);
  };

  const [visibility_income, setVisibility_income] = useState("0");
  const [visibility_income_family, setVisibility_income_family] = useState("0");

  const [initiated_visibility, setInitiatedVisibility] = useState("");
  const [path_since_visiblity, setPathSinceVisiblity] = useState("");
  const [official_sevadar_visibility, setOfficialSevadarVisibility] =
    useState("");

  const handleChangeInitiated = (event) => {
    setInitiatedVisibility(event.target.value);
  };
  const handleChangePathSince = (event) => {
    setPathSinceVisiblity(event.target.value);
  };
  const handleChangeSevadar = (event) => {
    setOfficialSevadarVisibility(event.target.value);
  };

  const handleChangeIncome = (event) => {
    setVisibility_income(event.target.value);
  };

  const handleChangeIncomeFamily = (event) => {
    setVisibility_income_family(event.target.value);
  };

  const submitAdvanceSearchForm = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("uniquecode", userDetails.uniquecode);

    if (SpritiualPath) {
      formData.append(
        "community",
        SpritiualPath.map((item) => item.value).join(",")
      );
    }

    formData.append("start_age", Start_age);
    formData.append("end_age", End_age);
    formData.append("start_height", startHeight);
    formData.append("end_height", endHeight);

    if (Marital) {
      formData.append(
        "marital_status",
        Marital.map((item) => item.value).join(",")
      );
    }

    formData.append("kids", visibility);

    if (Religion) {
      formData.append("religion", Religion.map((item) => item.value).join(","));
    }

    if (Toungess) {
      formData.append(
        "mother_tongue",
        Toungess.map((item) => item.value).join(",")
      );
    }

    if (Country) {
      formData.append("country", Country.map((item) => item.value).join(","));
    }

    if (State) {
      formData.append("state", State.map((item) => item.value).join(","));
    }

    if (City) {
      formData.append("city", City.map((item) => item.value).join(","));
    }

    formData.append("path_since", path_since_visiblity);
    formData.append("initiated", initiated_visibility);
    formData.append("official_sevadar", official_sevadar_visibility);

    if (qualification) {
      formData.append(
        "qualification",
        qualification.map((item) => item.value).join(",")
      );
    }

    formData.append("income", Income);
    formData.append("family_income", IncomeFamily);

    if (hobby) {
      formData.append("hobbies", hobby.map((item) => item.value).join(","));
    }

    try {
      const response = await fetch(`${baseUrl}/advanceSearch`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        setTimeout(function () {
          navigate("/nearme");
        }, 1500);

        // window.location.href = `/myprofile`;
      } else {
        toastr.error("Failed to search. Please try again.");
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
    }
  };

  const handleReset = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("uniquecode", userDetails.uniquecode);

    try {
      const response = await fetch(`${baseUrl}/reset`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        window.location.reload();
      } else {
        toastr.error("Failed to reset. Please try again.");
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
    }
  };
  
  const [profileId,setProfileId] = useState("");
  const handleChangeProfileId = async (event)=>{
    const profileId = event.target.value;
    setProfileId(profileId);
   
  }

  const searchProfileById = async (event) =>{
    event.preventDefault();
    if(profileId!='' && profileId!=userDetails.uniquecode){
      const whereObj = { uniquecode: profileId };
      const userData = await fetchData("*", "users", whereObj);
      if(userData.length>0){
        navigate('/user/'+ profileId);
      }else{
        toastr.error("Profile not found");
      }
     
    }else{
      toastr.error("Please enter profile id");
    }
  }
  return (
    <>
      <section className="w70 basic_search fcs-display m_auto">
        <form
          action="javascript:void(0)"
          method="post"
          id="advanceSearchForm"
          style={{ width: "100%" }}
          onSubmit={submitAdvanceSearchForm}
        >
          <div className="basic_search_left_bx">
            <div className="basic_search_card">
              <label>Spiritual Path</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={SpritiualPath}
                onChange={handleChange}
                options={communityes}
              />
            </div>
            <div className="basic_search_card">
              <label>Age</label>
              <div className="inputs fcs-display">
                <input
                  type="text"
                  placeholder="Start Age"
                  style={{ marginRight: "10px" }}
                  max={50}
                  min={18}
                  onChange={(e) => handleChangeAge(e, "start")}
                  value={Start_age}
                />{" "}
                <span
                  style={{
                    margin: "0 5px",
                    position: "relative",
                    left: "-5px",
                    top: "5px",
                    color: "#fff",
                  }}
                >
                  to
                </span>
                <input
                  type="text"
                  placeholder="End Age"
                  onChange={(e) => handleChangeAge(e, "end")}
                  value={End_age}
                  max={50}
                  min={18}
                />
              </div>
            </div>
            <div className="basic_search_card">
              <label>Height</label>
              <div className="basic_section fcs-display">
                <div className="filter_card">
                  <input
                    type="text"
                    placeholder="Start Height"
                    value={startHeight}
                    onFocus={() => handelFoucs("sheight_for_drop_bx")}
                    onBlur={() => handelFoucsout("sheight_for_drop_bx")}
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="sheight_for_drop_bx">
                    {height.map((el, i) => (
                      <li key={i} onClick={() => setStartHeight(el.lable)}>
                        {el.lable}
                      </li>
                    ))}
                  </div>
                </div>
                <span>To</span>
                <div className="filter_card">
                  <input
                    type="text"
                    placeholder="End Height"
                    value={endHeight}
                    onFocus={() => handelFoucs("eheight_drop_bx")}
                    onBlur={() => handelFoucsout("eheight_drop_bx")}
                  />
                  <i className="bi bi-caret-down-fill"></i>
                  <div className="drop_bx" id="eheight_drop_bx">
                    {height.map((el, i) => (
                      <li key={i} onClick={() => setEndHeight(el.lable)}>
                        {el.lable}
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="basic_search_card">
              <label>Marital Status</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Marital}
                onChange={handleChangeMarital}
                options={MaritalStatus}
              />
            </div>

            <div className="basic_search_card">
              <label>Kids</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="all1"
                    name="kids"
                    value="dosnt_matter"
                    checked={visibility === "dosnt_matter"}
                    onChange={handleChangeChildern}
                  />
                  <label htmlFor="all1">Doesn't Matter</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="connections1"
                    name="kids"
                    value="No"
                    checked={visibility === "No"}
                    onChange={handleChangeChildern}
                  />
                  <label htmlFor="connections1">No</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="pre_connections1"
                    name="kids"
                    value="ok_not_together"
                    checked={visibility === "ok_not_together"}
                    onChange={handleChangeChildern}
                  />
                  <label htmlFor="pre_connections1">
                    ok, if not saying together
                  </label>
                </div>
              </div>
            </div>

            <div className="basic_search_card">
              <label>Religion</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Religion}
                onChange={handleChangeReligion}
                options={ReligonStatus}
              />
            </div>
            <div className="basic_search_card">
              <label>Mother Tongue</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={Toungess}
                onChange={handleChangeTounge}
                options={ToungeStatus}
              />
            </div>

            {/* <div className="basic_search_card" >
                        <label>Photo Settings</label>
                        <div className="radio_btns fc-display">
                            <div className="card">
                                <input type="checkbox" name="" id="" />
                                <label>Visible to all</label>
                            </div>
                            <div className="card">
                                <input type="checkbox" name="" id="" />
                                <label>Protected Photo</label>
                            </div>
                        </div>
                    </div> */}
            <div className="head_band w60">
              <h3>Location</h3>
            </div>
            <div className="basic_search_card">
              <label>Country Living in</label>
              <Select
                className="w70"
                style={{ zIndex: 99999, position: "relative" }}
                isMulti
                value={Country}
                onChange={handleChangeCountry}
                options={CountryStatus}
              />
            </div>
            <div className="basic_search_card">
              <label>State</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={State}
                onChange={handleChangeState}
                options={StateList}
              />
            </div>

            <div className="basic_search_card">
              <label>City</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={City}
                onChange={handleChangeCity}
                options={CityList}
              />
            </div>

            <div className="head_band w60">
              <h3>Spiritual Details</h3>
            </div>

            <div className="basic_search_card">
              <label>Following the path since birth</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="path_since"
                    name="path_since"
                    value="Yes"
                    checked={path_since_visiblity === "Yes"}
                    onChange={handleChangePathSince}
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="path_since1"
                    name="path_since"
                    value="No"
                    checked={path_since_visiblity === "No"}
                    onChange={handleChangePathSince}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </div>
            </div>

            <div className="basic_search_card">
              <label>Initiated</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="initiated"
                    name="initiated"
                    value="Yes"
                    checked={initiated_visibility === "Yes"}
                    onChange={handleChangeInitiated}
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="initiated1"
                    name="initiated"
                    value="No"
                    checked={initiated_visibility === "No"}
                    onChange={handleChangeInitiated}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </div>
            </div>

            <div className="basic_search_card">
              <label>Official Sevadar</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="official_sevadar"
                    name="official_sevadar"
                    value="Yes"
                    checked={official_sevadar_visibility === "Yes"}
                    onChange={handleChangeSevadar}
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="official_sevadar1"
                    name="official_sevadar"
                    value="No"
                    checked={official_sevadar_visibility === "No"}
                    onChange={handleChangeSevadar}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </div>
            </div>

            <div className="head_band w60">
              <h3>Education & Income</h3>
            </div>
            <div className="basic_search_card">
              <label>Education</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={qualification}
                onChange={handleQualification}
                options={QualificationList}
              />
            </div>

            <div className="basic_search_card">
              <label>Income</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="income1"
                    name="visibility_income"
                    value="0"
                    checked={visibility_income === "0"}
                    onChange={handleChangeIncome}
                  />
                  <label htmlFor="all1">Doesn't Matter</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="income2"
                    name="visibility_income"
                    value="1"
                    checked={visibility_income === "1"}
                    onChange={handleChangeIncome}
                  />
                  <label htmlFor="connections1">Specify on income range</label>
                </div>
              </div>
            </div>
            {visibility_income == 1 ? (
              <div className="basic_search_card">
                <label>Income Range</label>
                <div className="basic_section fcs-display">
                  <div className="filter_card" style={{ width: "250px" }}>
                    <input
                      type="text"
                      placeholder="Select Income"
                      value={Income}
                      onFocus={() => handelFoucs("sincome_for_drop_bx")}
                      onBlur={() => handelFoucsout("sincome_for_drop_bx")}
                    />
                    <i className="bi bi-caret-down-fill"></i>
                    <div className="drop_bx" id="sincome_for_drop_bx">
                      {IncomeRange.map((el, i) => (
                        <li key={i} onClick={() => setIncome(el.lable)}>
                          {el.lable}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="basic_search_card">
              <label>Family Income</label>
              <div className="radio_btns fc-display">
                <div className="card">
                  <input
                    type="radio"
                    id="visibility_income_family"
                    name="family_income"
                    value="0"
                    checked={visibility_income_family === "0"}
                    onChange={handleChangeIncomeFamily}
                  />
                  <label htmlFor="all1">Doesn't Matter</label>
                </div>

                <div className="card">
                  <input
                    type="radio"
                    id="visibility_income_family1"
                    name="family_income"
                    value="1"
                    checked={visibility_income_family === "1"}
                    onChange={handleChangeIncomeFamily}
                  />
                  <label htmlFor="connections1">Specify on income range</label>
                </div>
              </div>
            </div>
            {visibility_income_family == 1 ? (
              <div className="basic_search_card">
                <label>Family Income Range</label>
                <div className="basic_section fcs-display">
                  <div className="filter_card" style={{ width: "250px" }}>
                    <input
                      type="text"
                      placeholder="Select Income"
                      value={IncomeFamily}
                      onFocus={() => handelFoucs("sincome_for_drop_bx_family")}
                      onBlur={() =>
                        handelFoucsout("sincome_for_drop_bx_family")
                      }
                    />
                    <i className="bi bi-caret-down-fill"></i>
                    <div className="drop_bx" id="sincome_for_drop_bx_family">
                      {IncomeRange.map((el, i) => (
                        <li key={i} onClick={() => setIncomeFamily(el.lable)}>
                          {el.lable}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="head_band w60">
              <h3>Hobbies</h3>
            </div>

            <div className="basic_search_card">
              <label>Hobbies</label>
              <Select
                className="w70"
                style={{ zIndex: 9999, position: "relative" }}
                isMulti
                value={hobby}
                onChange={handlehobbiesList}
                options={hobbiesList}
              />
            </div>

            <div className="basic_search_card fcc-display w100">
              <button className="btn_reset n_padding ">Search</button>
              <button className="btn_reset reset" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        </form>

        {/* Right Section  */}
        <div className="basic_search_right_bx">
          <div className="filter">
            <h3>
              <i className="bi bi-search"></i> Profile ID Search
            </h3>
            <div className="filter_card">
            <div className="filter_head fcs-display w100">
                <input
                  type="search"
                  name=""
                  id=""
                  value={profileId}
                  onChange={handleChangeProfileId}
                  placeholder="Enter Profile ID"
                  className="w80"
                />
                <button className="reset_btn" onClick={searchProfileById}>Go</button>
              </div>
            </div>
          </div>
          <div className="profile ad_p fcc-display w100">
            <img src="iimg/contact_call.jpg" alt="" onClick={navigate('/plan')} />
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvancedSearch;
