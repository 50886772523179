import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import {
    fetchCommunity,
    fetchIncomeRange,
    fetchMaritalStatus,
    fetchData,
    fetchCountry,
    fetchStateMultiple
  } from "../../helpers/databaseHelpers";

let Shortlisted = () => {
    let baseUrl = 'https://satsangirishtey.com:5000';
    const [userDetails, setUserDetails] = useState([]);
    const [filterChange, setFilterChange] = useState(false);
    const [recently_joined, setRecentlyJoined] = useState('all');
    const [active_members, setActiveMembers] = useState('all');
    const [MatchesData, setMatchesData] = useState([]);
    let navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    const [CommunityList, setCommunityList] = useState([]);
    const [maritalStatusList, setMaritalStatusList] = useState([]);
    const [incomeList, setIncomeList] = useState([]);
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    if (!token) {
        console.log("No token found. Redirecting to login.");
        navigate("/");
    }

    const [community, setCommunity] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [income,setIncome] = useState([]);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);

  const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
    };

  const handlechangeCommunity = (event) => {
    const { value } = event.target;
    const ccc = typeof value === "string" ? value.split(",") : value;
    setCommunity(ccc);
    setFilterChange(true);
  };



  const handleChangeMaritalStatus = (event) => {
    const { value } = event.target;
    const maritalStatusV = typeof value === "string" ? value.split(",") : value;
    setMaritalStatus(maritalStatusV);
    setFilterChange(true);
  };

  const handlechangeIncome = (event) => {
    const { value } = event.target;
    const ccc = typeof value === "string" ? value.split(",") : value;
    setIncome(ccc);
    setFilterChange(true);
  };

    useEffect(() => {
        const loadDetails = async () => {
            try {
                const whereObj = { token: token };
                const userData = await fetchData("*", "users", whereObj);
                setUserDetails(userData[0]);
            } catch (error) {
                console.error("Error loading user details:", error);
            }
        };
        loadDetails();

        const loadCommunity = async () => {
            try {
              const communityData = await fetchCommunity();
              setCommunityList(communityData);
            } catch (error) {
              console.error("Error loading community:", error);
            }
          };
          loadCommunity();

          const loadMarital = async () => {
            try {
              const maritalData = await fetchMaritalStatus();
              setMaritalStatusList(maritalData);
            } catch (error) {
              console.error("Error loading marital:", error);
            }
          };
    
          loadMarital();

          const loadIncome = async () => {
            try {
                const incomes = await fetchIncomeRange();
                setIncomeList(incomes);
            } catch (error) {
                console.error("Error loading income:", error);
            }
        };
        loadIncome();

        const loadCountry = async () => {
            try {
              const countryData = await fetchCountry();
              setCountryList(countryData);
            } catch (err) {
              console.error("Error loading country:", err);
            }
          };
    
          loadCountry();

    }, [token]);

    useEffect(() => {
     
        const loadNewMatches = async () => {
            try {
                let user_id = userDetails.uniquecode;
                let filterData = {
                    recently_joined,
                    active_members,
                    community,
                    maritalStatus,
                    income,
                    country,
                    state
                };
                const response = await fetch(`${baseUrl}/fetchShortlistedMatches`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id, filterData }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const newMatches = await response.json();
                setMatchesData(newMatches.data);
            } catch (error) {
                console.error('Error loading new matches:', error);
            }
        };

        if (filterChange) {
            loadNewMatches();
            setFilterChange(false); // Reset filterChange after loading matches
        }else{
            loadNewMatches();
        }

    }, [userDetails.uniquecode, filterChange]);

    const loadState = async (country) => {
        try {
          const stateData = await fetchStateMultiple(country);
          setStateList(stateData);
        } catch (err) {
          console.error("Error loading state:", err);
        }
      };

    const handleChangeCountry = (event) => {
        const { value } = event.target;
        const coun = typeof value === "string" ? value.split(",") : value;
        const _countryNames = [];
        coun.map((co) => {
          _countryNames.push({ label: co, value: co });
        });
    
        setState([]);
        loadState(_countryNames);
        setCountry(coun);
        setFilterChange(true);
      };

      const handleChangeState = (event) => {
        const { value } = event.target;
        const stateV = typeof value === "string" ? value.split(",") : value;
        const _stateNames = [];
        stateV.map((st) => {
          _stateNames.push({ label: st, value: st });
        });
      
     
        setState(stateV);
        setFilterChange(true);
      };
    

    const handleOptionChange = (setter) => (event) => {
        setter(event.target.value);
        setFilterChange(true);
    };

    const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const [isConnected, setIsConnected] = useState(false);

    const handleConnectNow = () => {
        setIsConnected(true);
    };


    useEffect(() => {

        Array.from(document.getElementsByClassName('filter_head')).forEach((el, i) => {
            el.addEventListener('click', () => {
                const icon = el.getElementsByTagName('i')[0];
                const radioSection = document.getElementsByClassName('radio_section')[i];
              
              if (icon.classList.contains('bi-plus-square-fill')) {
                  icon.classList.remove('bi-plus-square-fill');
                  icon.classList.add('bi-dash-square-fill');
                  radioSection.classList.add('radio_sec_active');
                  
              } else {
                  icon.classList.remove('bi-dash-square-fill');
                  icon.classList.add('bi-plus-square-fill');
                  radioSection.classList.remove('radio_sec_active');
               
              }
          });
      });
  
  }, [])

  let [height , setHeight] = useState('0');
  

  useEffect(() => {
      setHeight(document.getElementsByClassName('filter')[0].clientHeight);
  }, [userDetails.uniquecode,filterChange])
    return (
        <>
            <section className="w80 new_matches fcs-display m_auto">
                <div className="match_left_bx" style={{width:"25%"}}>
                    <div className="filter">
                        <h3><i className="bi bi-filter-circle-fill"></i> Search</h3>
                        <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Recently Joined</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={recently_joined === 'all'}
                                        onChange={handleOptionChange(setRecentlyJoined)}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinDay"
                                        checked={recently_joined === 'withinDay'}
                                        onChange={handleOptionChange(setRecentlyJoined)}
                                    />
                                    <label>Within a day</label>
                                </div>

                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinWeek"
                                        checked={recently_joined === 'withinWeek'}
                                        onChange={handleOptionChange(setRecentlyJoined)}
                                    />
                                    <label>Within a week</label>

                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinMonth"
                                        checked={recently_joined === 'withinMonth'}
                                        onChange={handleOptionChange(setRecentlyJoined)}
                                    />
                                    <label>Within a month</label>
                                </div>
                            </div>
                        </div> 
                        {/* <div className="filter_card">
                            <div className="filter_head fcs-display w100">
                                <h6>Active Members</h6>
                                <i className="bi bi-plus-square-fill"></i>
                            </div>
                            <div className="radio_section">
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="all"
                                        checked={active_members === 'all'}
                                        onChange={handleOptionChange(setActiveMembers)}
                                    />
                                    <label>All</label>
                                </div>
                                <div className="radio_card">
                                    <input
                                        type="checkbox"
                                        value="withinaMonth"
                                        checked={active_members === 'withinaMonth'}
                                        onChange={handleOptionChange(setActiveMembers)}
                                    />
                                    <label>Within a month (52)</label>
                                </div>
                            </div>
                        </div> */}
                       
                        <div className="filter_card" style={{backgroundColor:"white"}}>
                            <div className="filter_head fcs-display w100">
                                <h6>Spritual Path</h6>
                                <i className="bi  bi-people-fill"></i>
                            </div>
                            <div className="radio_saction">
                                <div className="radio_card">
                                    <FormControl sx={{ m: 1, mt: 3, width: 250 }}>
                                    <InputLabel id="demo-multiple-checkbox-label" style={{color:"black"}}>
                                    Spritiual path
                                    </InputLabel>
                                        <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={community}
                                        onChange={handlechangeCommunity}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                        >
                                        {CommunityList.map((name) => (
                                            <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={community.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                           
                            </div>
                        </div>

                        <div className="filter_card" style={{backgroundColor:"white"}}>
                            <div className="filter_head fcs-display w100">
                                <h6>Marital Status</h6>
                                <i className="bi  bi-people-fill"></i>
                            </div>
                            <div className="radio_saction">
                                <div className="radio_card">
                                    <FormControl sx={{ m: 1, mt: 3, width: 250 }}>
                                    <InputLabel id="demo-multiple-checkbox-label" style={{color:"black"}}>
                                    Marital Status
                                    </InputLabel>
                                        <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={maritalStatus}
                                        onChange={handleChangeMaritalStatus}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                        >
                                        {maritalStatusList.map((name) => (
                                            <MenuItem key={name.name} value={name.name}>
                                            <Checkbox checked={maritalStatus.indexOf(name.name) > -1} />
                                            <ListItemText primary={name.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                           
                            </div>
                        </div>

                        <div className="filter_card" style={{backgroundColor:"white"}}>
                            <div className="filter_head fcs-display w100">
                                <h6>Annual Income</h6>
                                <i className="bi  bi-people-fill"></i>
                            </div>
                            <div className="radio_saction">
                                <div className="radio_card">
                                    <FormControl sx={{ m: 1, mt: 3, width: 250 }}>
                                    <InputLabel id="demo-multiple-checkbox-label" style={{color:"black"}}>
                                    Annual Income
                                    </InputLabel>
                                        <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={income}
                                        onChange={handlechangeIncome}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                        >
                                        {incomeList.map((name) => (
                                            <MenuItem key={name.lable} value={name.lable}>
                                            <Checkbox checked={income.indexOf(name.lable) > -1} />
                                            <ListItemText primary={name.lable} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                           
                            </div>
                        </div>

                        <div className="filter_card" style={{backgroundColor:"white"}}>
                            <div className="filter_head fcs-display w100">
                                <h6>Country</h6>
                                <i className="bi  bi-people-fill"></i>
                            </div>
                            <div className="radio_saction">
                                <div className="radio_card">
                                    <FormControl sx={{ m: 1, mt: 3, width: 250 }}>
                                    <InputLabel id="demo-multiple-checkbox-label" style={{color:"black"}}>
                                    Country
                                    </InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={country}
                                        onChange={handleChangeCountry}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                        >
                                        {CountryList.map((countries) => (
                                            <MenuItem key={countries.country} value={countries.country}>
                                            <Checkbox
                                                checked={country.indexOf(countries.country) > -1}
                                            />
                                            <ListItemText primary={countries.country} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                           
                            </div>
                        </div>
                        <div className="filter_card" style={{backgroundColor:"white"}}>
                            <div className="filter_head fcs-display w100">
                                <h6>State</h6>
                                <i className="bi  bi-people-fill"></i>
                            </div>
                            <div className="radio_saction">
                                <div className="radio_card">
                                    <FormControl sx={{ m: 1, mt: 3, width: 250 }}>
                                    <InputLabel id="demo-multiple-checkbox-label" style={{color:"black"}}>
                                    State
                                    </InputLabel>
                                    <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={state}
                                    onChange={handleChangeState}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(", ")}
                                    MenuProps={MenuProps}
                                    >
                                    {StateList.map((stat) => (
                                        <MenuItem key={stat.state} value={stat.state}>
                                        <Checkbox checked={state.indexOf(stat.state) > -1} />
                                        <ListItemText primary={stat.state} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    </FormControl>
                                </div>
                           
                            </div>
                        </div>

                    </div>
                </div>
                <div className="match_right_bx" style={{height: `${height}px`}}>
                { MatchesData.length > 0 ? (
                  MatchesData.map((el, i) => (
                        <div className="card_matches w-100 fcs-display">
                            <div className="img_bx">
                                <img src={el.profile_pic} alt="profile pic" />
                            </div>
                            <div className="content_bx">
                                <h4>{el.first_name} {el.last_name} <i className="bi bi-shield-check"></i></h4>
                                <div className="cont_head w-100 fcs-display">
                                    <h6><i className="bi bi-chat-right-dots-fill"></i> Online 3h ago</h6>
                                   
                                </div>
                                <ul className="fcs-display w-100">
                                    <li>{(el.age)? el.age : calculateAge(el.d_o_b)} yrs, {el.height}</li>
                                    <li>{el.community}</li>
                                    <li>{el.country}, {el.state}</li>
                                    <li>{el.marital_status}</li>
                                    <li>{el.mother_tongue}</li>
                                    <li>{el.religion}</li>
                                </ul>
                                <p>{el.about}<Link to={`/user/${el.uniquecode}`}>Show more.</Link></p>
                            </div>
                            <div className="connect_bx">
                                {
                                    (userDetails.account_type=='pro')?(<div className="connected_profile w-100 fcc-display" >
                                        
                                        <button><i className="bi bi-telephone-fill"></i> Call</button>
                                        <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                        <button><i className="bi bi-chat-right-dots-fill"></i> Chat</button>
                                    </div>):(
                                        !isConnected ? (
                                        <div className="connect_profile w-100 fcc-display connectNow" onClick={handleConnectNow}>
                                            <h6>Like this profile?</h6>
                                            <i className="bi bi-check-circle-fill"></i>
                                            <p>Connect Now</p>
                                        </div>
                                    ) : (
                                        <div className="connected_profile w-100 fcc-display">
                                            <p><Link to='#'>Upgrade</Link> to Contact {el.gender === 'male' ? 'him' : 'her'} directly</p>
                                            <button ><i className="bi bi-telephone-fill"></i> Call</button>
                                            <button ><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                            <button ><i className="bi bi-chat-right-dots-fill"></i> Chat</button>
                                        </div>
                                    ))
                                }
                                
                                
                            </div>
                        </div>
                    ))
                    ) : (
                    <p>No recent visitors found.</p>
                    )}
                </div>
            </section>
        </>
    )
}

export default Shortlisted;