import React, { useState, useEffect } from "react";
import { Link,useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import {
    fetchData,
  } from "../../helpers/databaseHelpers";

let User = () =>
{

    let navigate = useNavigate();
    const token = localStorage.getItem("authToken");
    
    if (!token) {
      console.log("No token found. Redirecting to login.");
      navigate("/");
    }

    const [me, setMe] = useState([]);
    useEffect(() => {
        const loadMe = async () => {
            try {
                const whereObjj = { token};
                const meData = await fetchData("*", "users", whereObjj);
                setMe(meData[0]);
            } catch (error) {
                console.error("Error loading user details:", error);
            }
        };
        loadMe();
    }, [token]);

        const { uniquecode } = useParams();
        const [userDetails, setUserDetails] = useState([]);
        useEffect(() => {
            const loadDetails = async () => {
                try {
                    const whereObj = { uniquecode};
                    const userData = await fetchData("*", "users", whereObj);
                    setUserDetails(userData[0]);
                } catch (error) {
                    console.error("Error loading user details:", error);
                }
            };
            loadDetails();
        }, [uniquecode]);

        const copyToClipboard = (text) => {
          navigator.clipboard.writeText(text).then(() => {
            document.getElementById('copyIcon').classList.remove('bi-clipboard-fill');
            document.getElementById('copyIcon').classList.add('bi-clipboard-check-fill');
            setTimeout(() => {
                document.getElementById('copyIcon').classList.add('bi-clipboard-fill');
                document.getElementById('copyIcon').classList.remove('bi-clipboard-check-fill');
                
            }, 2000);
          }).catch(err => {
            console.error('Failed to copy text: ', err);
          });
        };
      
       
        const handleClick = () => {
          const textToCopy = document.getElementById('copyText').innerText;
          copyToClipboard(textToCopy);
        };

        const calculateAge = (dateOfBirth) => {
            const today = new Date();
            const birthDate = new Date(dateOfBirth);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
    
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
    
            return age;
        };

    
    return (
        <>
            <section className="w55 new_matches user_details fcs-display m_auto">
                
                <div className="match_right_bx " >
                    <div className="card_matches w-100 fcs-display">
                        <div className="img_bx">
                            <img src={userDetails.profile_pic} alt="" />
                        </div>
                        <div className="content_bx">
                            <h4>{userDetails.first_name} {userDetails.last_name} <i className="bi bi-shield-check"></i></h4>
                            <div className="cont_head w-100 fcs-display">
                                <h6>{userDetails.community}</h6>
                                <div className="r_fav">
                                    <span style={{marginRight: '50px', position:'relative', top: '-15px'}}>Online 3h ago</span>
                                </div>
                            </div>
                          
                            <ul className="fcs-display w-100">
                                    <li>{(userDetails.age)? userDetails.age : calculateAge(userDetails.d_o_b)} yrs, {userDetails.height}</li>
                                    <li>{userDetails.community}</li>
                                    <li>{userDetails.country}, {userDetails.state}</li>
                                    <li>{userDetails.marital_status}</li>
                                    <li>{userDetails.mother_tongue}</li>
                                    <li>{userDetails.religion}</li>
                                </ul>
                            
                        </div>
                        <div className="connect_bx">
                            <div className="connect_profile w-100 fcc-display" >
                                <h6>Like this profile ?</h6>
                                <i className="bi bi-check-circle-fill"></i>
                                <p>Connect Now</p>
                                <i className="bi bi-heart-fill" style={{fontSize:'20px', border: 'none'}}></i>
                            </div>
                            <div className="connected_profile w-100 fcc-display" style={ { display: "none" } }>
                                <p><Link to='/plan'>Upgrade</Link>to
                                    Contact her directly</p>
                                <button><i className="bi bi-telephone-fill"></i> Call</button>
                                <button><i className="bi bi-whatsapp"></i> Whatsaap</button>
                                <button><i className="bi bi-chat-right-dots-fill"></i> Call</button>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>

            <div className="deatils_user_bx w55 fcs-display m_auto">
                <div className="left_bx"></div>
                <div className="right_bx">
                    <div className="id_btns">
                        <button onClick={handleClick}>ID:<span id="copyText">{uniquecode}</span> <i id="copyIcon" className="bi bi-clipboard-fill"></i></button>
                        <button>Profile creted by {userDetails.profile_type}</button>
                    </div>

                    <h3><img src="/icon/image00009.png" alt="" /> About {userDetails.first_name} {userDetails.last_name}</h3>
                    <p>{userDetails.about}</p>
                    <h3><img src="/icon/image00005.png" alt="" /> Contact Details</h3>
                    <div className="contact_det "> {/*  use (contact_det_active) class */ }
                        <h4><i className="bi bi-telephone-fill"></i> +919521186367</h4>
                        <h4><i className="bi bi-envelope-fill"></i> roman@gmail.com</h4>
                        <i class="bi bi-shield-lock-fill mail_icon"></i>
                    </div>
                    <h3><img src="/icon/image00013.png" alt="" /> Spiritual Details</h3>

                    <li><img src="/icon/image00007.png" alt="" /> Path : {userDetails.community}</li>
                    <li><img src="/icon/image00016.png" alt="" /> Path initiated : {userDetails.path_initiated ? userDetails.path_initiated:'No'}</li>
                    <li><img src="/icon/image00002.png" alt="" /> Following the Path since birth : {userDetails.path_since ? userDetails.path_since:'No'}</li>
                    <li><img src="/icon/image00020.png" alt="" /> Diet : {userDetails.diet}</li>
                    {/* <li><img src="/icon/image00018.png" alt="" /> Satsangi in Family: Mother, Father, Sister</li> */}

                    <h3><img src="/icon/image00001.png" alt="" /> Background</h3>

                    <li><img src="/icon/image00014.png" alt="" /> {userDetails.religion}</li>
                    <li><img src="/icon/image00018.png" alt="" /> {userDetails.hobies_intrest}</li>
                    <li><img src="/icon/image00006.png" alt="" /> Lives in {userDetails.city} , {userDetails.state} {userDetails.country}</li>

                    <h3><img src="/icon/image00003.png" alt="" /> Fmaily Details</h3>

                    <li><img src="/icon/image00012.png" alt="" /> Father: {userDetails.father_ocupation}, Mother: {userDetails.mother_occupation}</li>
                    <li><img src="/icon/image00019.png" alt="" /> {userDetails.no_of_sisters} sisters, {userDetails.no_of_brothers} brother</li>
                    <li><img src="/icon/image00017.png" alt="" /> Family Income: {userDetails.family_financial_status}</li>

                    <h3><img src="/icon/image00004.png" alt="" /> Education Details</h3>

                    <li><img src="/icon/image00004.png" alt="" /> {userDetails.qualification}</li>
                    <li><img src="/icon/image00009.png" alt="" /> {userDetails.designation}</li>
                    <li><img src="/icon/image00017.png" alt="" /> {userDetails.income}</li>
                    
                    <h3><img src="/icon/image00008.png" alt="" /> Partner Preference</h3>

                    <div className="partner_pre_user_bx  w100"> {/* hide deatils so add class (hide_pp_details) */}
                        <div className="both_user w80 m_auto fcs-display">
                            <div className="user_card user_card12">
                                <img src={me.profile_pic} alt="" />
                                <h4>{me.first_name} {me.last_name}</h4>
                            </div>
                            <div className="user_card">
                               <h6>You match 4/5 of her preference</h6>
                            </div>
                            <div className="user_card user_card12">
                                <img src={userDetails.profile_pic} alt="" />
                                <h4>{userDetails.first_name} {userDetails.last_name}</h4>
                            </div>
                        </div>
                        <div className="pp_details w100">
                            <div className="pp_card">
                                <div className="labels">
                                    <h5>Age</h5>
                                    <h6>35 to 43</h6>
                                </div>
                                 <i className="bi bi-check-circle-fill"></i> {/* <i class="bi bi-x-circle-fill"></i>  not match */}
                            </div>
                            <div className="pp_card">
                                <div className="labels">
                                    <h5>Height</h5>
                                    <h6>5' 0" (152.4 cm) to 7' 0" (213.36 cm)</h6>
                                </div>
                                 <i className="bi bi-check-circle-fill"></i> {/*<i class="bi bi-x-circle-fill"></i>  not match */}
                            </div>
                            <div className="pp_card">
                                <div className="labels">
                                    <h5>Religion / Spritiual Path</h5>
                                    <h6>Buddhism,Islam,Sikhism,Jainism,Hinduism,Christianity,Other/ Brahma Kumaris,Dera Sacha Sauda,Jagat Guru Rampal Ji,Guruji</h6>
                                </div>
                                <i className="bi bi-x-circle-fill"></i>  {/*<i class="bi bi-x-circle-fill"></i>  not match */}
                            </div>
                            <div className="pp_card">
                                <div className="labels">
                                    <h5>Mother tongue:</h5>
                                    <h6>Hindi,English,Bengali,Telugu,Punjabi,Marathi,Gujrati,Marwari,Tamil,</h6>
                                </div>
                                <i className="bi bi-check-circle-fill"></i>  {/*<i class="bi bi-x-circle-fill"></i>  not match */}
                            </div>
                            <div className="pp_card">
                                <div className="labels">
                                    <h5>Currently Living in</h5>
                                    <h6>India,Assam,Dibrugarh / North West</h6>
                                </div>
                               <i className="bi bi-check-circle-fill"></i>   {/*<i class="bi bi-x-circle-fill"></i>  not match */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default User;