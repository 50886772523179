import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../helpers/databaseHelpers";
import Select from "react-select";
import "toastr/build/toastr.min.css";
import toastr from "toastr";

import {
  fetchProfiles,
  fetchCommunity,
  fetchReligion,
  fetchIncomeRange,
  fetchQualification,
  fetchCountry,
  fetchState,
  fetchStateMultiple,
  fetchCityMultiple,
  fetchCity,
  fetchMaritalStatus,
  fetchMotherTongue,
  fetchHeight,
} from "../../helpers/databaseHelpers";

let My_profile = () => {
  let baseUrl = 'https://satsangirishtey.com:5000';
  const [height, setHeight] = useState([]);
  const [startHeight, setStartHeight] = useState("");
  const [endHeight, setEndHeight] = useState("");
  const [communityes, setCommunityes] = useState([]);
  const [MaritalStatus, setMaritalStatus] = useState([]);
  const [Religions, SetReligions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  const [annualIncomes, setAnnualIncomes] = useState([]);
  const [highQualificaion, setHighQualificaion] = useState([]);
  const [qualification, setQualification] = useState("");
  

  
  const [incomeRange, setIncomeRange] = useState([]);
  const [income, setIncome] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [SpritiualPath, setSpritiualPath] = useState([]);
  const [Marital, setMarital] = useState("");
  const [MotherTongues, setMotherTontues] = useState([]);
  const [MotherTongue, setMotherTongue] = useState("");
  const [Religion, SetReligion] = useState(Religions[0]);
  const [workingWith, SetWorkingWith] = useState("");
  const [designation, SetDesignation] = useState("");
  const [professionArea, SetProfessionArea] = useState("");

  const [diet, setDiet] = useState("");
  const [district, setDistrict] = useState("");
  let [Start_age, SetStart_age] = useState("18");
  let [End_age, SetEnd_age] = useState("27");
  const [triggerEdit, setTriggerEdit] = useState(false);
  const [updateCompleted, setUpdateCompleted] = useState(false);
  const [StartHeightInct, setStartHeightInct] = useState("");
  const [EndHeightInct, setEndHeightInct] = useState("");
  const [userDetails, setUserDetails] = useState([]);

  const [userEdit, setUserEdit] = useState(false);
  let navigate = useNavigate();

  //user update work

  const [about,setAbout] = useState("");
  const [userAge,setUserAge] = useState("");
  const [userDOB,setUserDOB] = useState("");
  const [maritalUserStatus,setUserMaritalStatus] = useState("");
  const [userHeight,setUserHeight] = useState("");
  const [UserHeightInch,setUserHeightInch] = useState("");
  
  const [userCountry,setUserCountry] = useState("");
  const [userState,setUserState] = useState("");
  const [userCity,setUserCity] = useState("");
  const [userDiet,setUserDiet] = useState("");
  const [userPersonalValues,setUserPersonalValues] = useState("");
  const [userSunSign,setUserSunSign] = useState("");
  const [userBloodGroup,setUserBloodGroup] = useState("");
  const [userHealthInfo,setUserHealthInfo] = useState("");
  const [userDisablity,setUserDisablity] = useState("");
  const [userMotherDetails,setUserMotherDetails] = useState("");
  const [userFatherDetails,setUserFatherDetails] = useState("");
  const [userFamilyLocation,setUserFamilyLocation] = useState("");
  const [userNoOfSis,setUserNoOfSis] = useState("");
  const [userNoOfBro,setUserNoOfBro] = useState("");
  const [userFinancialStatus,setFinancialStatus] = useState("");
  const [userQualification,setUsrQualification] = useState("");
  const [userCollage,setUserCollage] = useState("");
  const [userWorkingWith,setUserWorkingWith] = useState("");
  const [userDesignation,setUserDesignation] = useState("");
  const [userIncome,setUserIncome] = useState("");
  const [userZipcode,setUserZipCode] = useState("");
  const [UserHobbies,setUserHobbies] = useState("");
  const [UserCompany,setUserCompany] = useState("");
  const [UserResidencyStatus,setUserResidencyStatus] = useState("");
  const [MaritalList,setMaritalList] = useState([]);
  const [CountryList,setCountryList] = useState([]);
  const [StateList,setStateList] = useState([]);
  const [CityList,setCityList] = useState([]);
  const [UserQualification, setUserQualification] = useState("");
  const [userGrewUp,setUserGrewUp] = useState("");
  const [userCurrentResidence,setUserCurrentResidence] = useState("");
  const [HobbiesList,setHobbiesList] = useState([]);
  const [HobbiesListMultiple,setHobbiesListMulitple] = useState([]);

  const [SpritualList,setSpritualList] = useState([]);
  const [ReligionList,setReligionList] = useState([]);
  const [MotherTngList,setMotherTngList] = useState([]);
  const [Path_since,setPath_since] = useState("");
  const [Path_initiated,setPath_initiated] = useState("");
  const [Official_sevadar,setOfficial_sevadar] = useState("");
  const [userSpritual,setUserSpritual] = useState("");
  const [userReligion,setUserReligion] = useState("");
  const [userMotherTng,setUserMotherTng] = useState("");
  const [kids,setKids] = useState("");

  function handleUserEdit() {
   
    document.getElementById("userDetails_set").style.display = "none";
    document.getElementById("userDetails_edit").style.display = "unset";
    document.getElementById("user_edit").style.display = "none";
    document.getElementById("user_update").style.display = "unset";
    setUserEdit(true);
  }

  
  const loadStateList = async (country) => {
    try {
      const stateData = await fetchState(country);
      setStateList(stateData);
    } catch (err) {
      console.error("Error loading state:", err);
    }
  };


  const loadCityList = async (country, state) => {
    try {
      const cityData = await fetchCity(country, state);
      setCityList(cityData);
    } catch (err) {
      console.error("Error loading city:", err);  
  }
};
  function changeCountry(country){
    setUserState("");
    setUserCity("");
    loadStateList(country)
  }

  function changeState(country,state){
    setUserCity("");
    loadCityList(country,state)
  }
  // userEdit

  //change profile pic
  
  const handleImageChange = async (event) => {
    setUserEdit(false);
    const file = event.target.files[0];
    event.preventDefault();
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("uniquecode", userDetails.uniquecode);
    try {
      const response = await fetch(`${baseUrl}/updateProfilePic`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        toastr.success(data.message);
        setUserEdit(true);
      } else {
        toastr.error('Error in uploading image!');
        setUserEdit(true);
      }
    } catch (error) {
      toastr.error('Error in uploading image!');
      setUserEdit(true);
    }
  };

  //change profile pic completed

  const UpdateUserDetails = async (event) => {
    setUserEdit(true);
    event.preventDefault();
    document.getElementById("user_update").style.display = "none";
    document.getElementById("userLoad").style.display = "unset";
    const formData = new FormData();
    formData.append("uniquecode", userDetails.uniquecode);
    formData.append("state", userState);
    formData.append("d_o_b", userDOB);
    formData.append("age", calculateAge(userDOB));
    
    formData.append("city", userCity);
    formData.append("qualification", userQualification);
    formData.append("designation", userDesignation);
    formData.append("company", UserCompany);

    formData.append("income", userIncome);
    formData.append("about", about);
    formData.append("height", userHeight);
    formData.append("height_in_inch", UserHeightInch);
    formData.append("marital_status", maritalUserStatus);
    formData.append("country", userCountry);
    formData.append("diet", userDiet);

    formData.append("personal_values", userPersonalValues);
    formData.append("alcohol", userSunSign);
    formData.append("blood_group", userBloodGroup);
    formData.append("health_information", userHealthInfo);
    formData.append("disability", userDisablity);
    formData.append("mother_occupation", userMotherDetails);
    formData.append("father_ocupation", userFatherDetails);

    formData.append("famliy_status", userFamilyLocation);
    formData.append("no_of_sisters", userNoOfSis);
    formData.append("no_of_brothers", userNoOfBro);
    formData.append("family_financial_status", userFinancialStatus);
    formData.append("collage", userCollage);
    formData.append("working_with", userWorkingWith);
    formData.append("residency_status", UserResidencyStatus);

    formData.append("zipcode", userZipcode);
   
    if (UserHobbies) {
      formData.append("hobies_intrest", UserHobbies.map((item) => item.value).join(","));
    }
   
    formData.append("grew_up_in", userGrewUp);
    formData.append("current_residence", userCurrentResidence);

    formData.append("path_since",Path_since);
    formData.append("path_initiated",Path_initiated);
    formData.append("official_sevadar",Official_sevadar);

    formData.append("community",userSpritual);
    formData.append("religion",userReligion);
    formData.append("mother_tongue",userMotherTng);
    formData.append("kids",kids);
    try {
      const response = await fetch(`${baseUrl}/updateUserDetails`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        setTimeout(function () {
          document.getElementById("userDetails_edit").style.display = "none";
          document.getElementById("userDetails_set").style.display = "unset";

          document.getElementById("user_edit").style.display =
            "unset";
          document.getElementById("user_update").style.display =
            "none";
          document.getElementById("userLoad").style.display = "none";
          toastr.success("User Details Updated Successfully.");
          setUserEdit(false);
          navigate("/myprofile");
        }, 1500);

        // window.location.href = `/myprofile`;
      } else {
        toastr.error("Failed to update preferences. Please try again.");
        document.getElementById("userLoad").style.display = "none";
        document.getElementById("user_update").style.display =
          "unset";
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
      console.error("Error updating preferences:", error);
      document.getElementById("userLoad").style.display = "none";
      document.getElementById("user_update").style.display =
        "unset";
    }
    
  }

  //user update work end

  const updatePartnerEdit = async (event) => {
    setUpdateCompleted(false);
    event.preventDefault();
    document.getElementById("partner_preference_update").style.display = "none";
    document.getElementById("partnerLoad").style.display = "unset";
    const formData = new FormData();
    formData.append("user_id", partnerPreference.user_id);
    formData.append("start_age", Start_age);
    formData.append("end_age", End_age);
    formData.append("start_height_inch", StartHeightInct);
    formData.append("end_height_inch", EndHeightInct);
    formData.append("start_height_lable", startHeight);
    formData.append("end_height_lable", endHeight);

    if (Religion) {
      formData.append("religion", Religion.map((item) => item.value).join(","));
    }
    if (MotherTongue) {
      formData.append(
        "mother_tongue",
        MotherTongue.map((item) => item.value).join(",")
      );
    }

    if (Marital) {
      formData.append(
        "marital_status",
        Marital.map((item) => item.value).join(",")
      );
    }

    if (country) {
      formData.append("country", country.map((item) => item.value).join(","));
    }

    if (state) {
      formData.append("state", state.map((item) => item.value).join(","));
    }

    if (city) {
      formData.append("city", city.map((item) => item.value).join(","));
    }

    if (SpritiualPath) {
      formData.append(
        "community",
        SpritiualPath.map((item) => item.value).join(",")
      );
    }

    formData.append("education", qualification);
    formData.append("working_with", workingWith);
    formData.append("profession_area", professionArea);
    formData.append("designation", designation);

    if (annualIncome) {
      formData.append("annual_income", annualIncome.map((item) => item.value).join(","));
    }
    // formData.append("annual_income", income);

    formData.append("profile_created_by", profile);
    formData.append("diet", diet);

    formData.append("district", district);

    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    try {
      const response = await fetch(`${baseUrl}/updatePartnerPref`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        setTimeout(function () {
          document.getElementById("partnerP_edit").style.display = "none";
          document.getElementById("partnerP_set").style.display = "unset";

          document.getElementById("partner_preference_edit").style.display =
            "unset";
          document.getElementById("partner_preference_update").style.display =
            "none";
          document.getElementById("partnerLoad").style.display = "none";
          toastr.success("Preferences Updated Successfully.");

          setUpdateCompleted(true);
          setTriggerEdit(false);
          navigate("/myprofile");
        }, 1500);

        // window.location.href = `/myprofile`;
      } else {
        toastr.error("Failed to update preferences. Please try again.");
        document.getElementById("partnerLoad").style.display = "none";
        document.getElementById("partner_preference_update").style.display =
          "unset";
      }
    } catch (error) {
      toastr.error("An error occurred. Please try again later.");
      console.error("Error updating preferences:", error);
      document.getElementById("partnerLoad").style.display = "none";
      document.getElementById("partner_preference_update").style.display =
        "unset";
    }
  };

  function handlePartnerEdit() {
    setUpdateCompleted(false);
    document.getElementById("partnerP_set").style.display = "none";
    document.getElementById("partnerP_edit").style.display = "unset";
    document.getElementById("partner_preference_edit").style.display = "none";
    document.getElementById("partner_preference_update").style.display =
      "unset";
    setTriggerEdit(true);
  }

  const token = localStorage.getItem("authToken");

  if (!token) {
    console.log("No token found. Redirecting to login.");
    navigate("/");
  }

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  
  useEffect(() => {
    const loadDetails = async () => {
      try {
        const whereObj = { token: token };
        const userData = await fetchData("*", "users", whereObj);
        
        setUserDetails(userData[0]);

        setAbout(userDetails.about);
        setUserAge(calculateAge(userDetails.d_o_b));
        setUserDOB(userDetails.d_o_b);
        setUserMaritalStatus(userDetails.marital_status);
        setUserHeight(userDetails.height);
        setUserHeightInch(userDetails.height_in_inch);
        setUserCountry(userDetails.country);
        setUserDiet(userDetails.diet);
        setUserPersonalValues(userDetails.personal_values);
        setUserSunSign(userDetails.alcohol);
        setUserBloodGroup(userDetails.blood_group);
        setUserHealthInfo(userDetails.health_information);
        setUserDisablity(userDetails.disability);
        setUserMotherDetails(userDetails.mother_occupation);
        setUserFatherDetails(userDetails.father_ocupation);
        setUserFamilyLocation(userDetails.famliy_status);
        setUserNoOfSis(userDetails.no_of_sisters);
        setUserNoOfBro(userDetails.no_of_brothers);
        setFinancialStatus(userDetails.family_financial_status);
        setUsrQualification(userDetails.qualification);
        setUserCollage(userDetails.collage);
        setUserIncome(userDetails.income);
        setUserWorkingWith(userDetails.working_with);
        setUserDesignation(userDetails.designation);
        setUserCompany(userDetails.company);
        setUserState(userDetails.state);
        setUserCity(userDetails.city);
        setUserResidencyStatus(userDetails.residency_status);
        setUserZipCode(userDetails.zipcode);

        if (userDetails.hobies_intrest) {
          const hobSet = userDetails.hobies_intrest
            .split(",")
            .map((hob) => ({ label: hob, value: hob }));
            setUserHobbies(hobSet);
        }

        setPath_since(userDetails.path_since);
        setPath_initiated(userDetails.path_initiated);
        setOfficial_sevadar(userDetails.official_sevadar);
        setUserSpritual(userDetails.community);
        setUserReligion(userDetails.religion);
        setUserMotherTng(userDetails.mother_tongue);
      
      } catch (error) {
        console.error("Error loading :", error);
      }
    };
    loadDetails();
  }, [token,userEdit]);

  const [partnerPreference, setPartnerPreference] = useState([]);
  useEffect(() => {
    const loadpref = async () => {
      try {
        if (userDetails.uniquecode) {
          let user_id = userDetails.uniquecode;
          const whereObj1 = { user_id: user_id };

          const pref = await fetchData("*", "partner_preferences", whereObj1);
          if(pref[0]){
          setPartnerPreference(pref[0]);
          setStartHeight(partnerPreference.start_height_lable);
          setEndHeight(partnerPreference.end_height_lable);
          setStartHeightInct(partnerPreference.start_height_inch);
          setEndHeightInct(partnerPreference.end_height_inch);
          if (partnerPreference.religion) {
            const relegionSet = partnerPreference.religion
              .split(",")
              .map((rel) => ({ label: rel, value: rel }));
            SetReligion(relegionSet);
          }

          if (partnerPreference.community) {
            const comSet = partnerPreference.community
              .split(",")
              .map((com) => ({ label: com, value: com }));
            setSpritiualPath(comSet);
          }

          if (partnerPreference.mother_tongue) {
            const motherSet = partnerPreference.mother_tongue
              .split(",")
              .map((mo) => ({ label: mo, value: mo }));
            setMotherTongue(motherSet);
          }

          if (partnerPreference.marital_status) {
            const mariStatus = partnerPreference.marital_status
              .split(",")
              .map((moa) => ({ label: moa, value: moa }));
            setMarital(mariStatus);
          }

          if (partnerPreference.country) {
            const countryD = partnerPreference.country
              .split(",")
              .map((countryS) => ({ label: countryS, value: countryS }));
            setCountry(countryD);
          }

          if (partnerPreference.state) {
            const stateD = partnerPreference.state
              .split(",")
              .map((stateS) => ({ label: stateS, value: stateS }));
            setState(stateD);
          }

          if (partnerPreference.city) {
            const cityD = partnerPreference.city
              .split(",")
              .map((citys) => ({ label: citys, value: citys }));
            setCity(cityD);
          }

          setQualification(partnerPreference.education);
          SetWorkingWith(partnerPreference.working_with);
          SetDesignation(partnerPreference.designation);
          SetProfessionArea(partnerPreference.profession_area);
          setIncome(partnerPreference.annual_income);
          setProfile(partnerPreference.profile_created_by);
          setDiet(partnerPreference.diet);
          setDistrict(partnerPreference.district);
          SetStart_age(partnerPreference.start_age);
          SetEnd_age(partnerPreference.end_age);

          const hash = window.location.hash;
          if (hash == '#partnerPref') {
            handleScrollsPartner();
          }
        }
      }
      } catch (error) {
        console.error("Error loading :", error);
      }
    };
    loadpref();
  }, [userDetails.uniquecode, triggerEdit, updateCompleted]);

  

  function convertInchesToCentimeters(inches) {
    if (inches && inches != "") {
      const centimeters = inches * 2.54;
      return centimeters;
    } else {
      return 0;
    }
  }

  useEffect(() => {

    const loadHobiesList = async () =>{
      try {
        const listData = await fetchData("*", "hobbies_intrest", { 'status': 'active' });
        setHobbiesList(listData);
        let hob_mul = []; 
        listData.map((hob) => {
          hob_mul.push({ label: hob.name, value: hob.name });
        });
        setHobbiesListMulitple(hob_mul);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    }

    loadHobiesList();

    const loadCommunity = async () => {
      try {
        const communityData = await fetchCommunity();
        setSpritualList(communityData);
        const communityess = [];
        communityData.map((comu) => {
          communityess.push({ label: comu.name, value: comu.name });
        });
        setCommunityes(communityess);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };
    loadCommunity();

    const loadHeight = async () => {
      try {
        const heightData = await fetchHeight();
        setHeight(heightData);
      } catch (error) {
        console.error("Error loading community:", error);
      }
    };

    loadHeight();

    const loadMarital = async () => {
      try {
        const maritalData = await fetchMaritalStatus();
        setMaritalList(maritalData);
        const _maritalData = [];
        maritalData.map((mor) => {
          _maritalData.push({ label: mor.name, value: mor.name });
         
        });
        setMaritalStatus(_maritalData);
        
      } catch (error) {
        console.error("Error loading marital:", error);
      }
    };

    loadMarital();

    const loadmotherTongue = async () => {
      try {
        const motherTongueData = await fetchMotherTongue();
        setMotherTngList(motherTongueData);
        const _MotherTontues = [];
        motherTongueData.map((mother) => {
          _MotherTontues.push({ label: mother.name, value: mother.name });
        });
        setMotherTontues(_MotherTontues);
      } catch (error) {
        console.error("Error loading mother tongue:", error);
      }
    };

    loadmotherTongue();

    const loadCountry = async () => {
      try {
        const countryData = await fetchCountry();
        setCountryList(countryData);
        const arr_4 = [];
        countryData.map((countr) => {
          arr_4.push({ label: countr.country, value: countr.country });
        });
        setCountries(arr_4);
      } catch (err) {
        console.error("Error loading country:", err);
      }
    };

    loadCountry();

    const loadQualificaiont = async () => {
      try {
        const qualificaionData = await fetchQualification();
        setHighQualificaion(qualificaionData);
      } catch (err) {
        console.error("Error loading qualification:", err);
      }
    };

    loadQualificaiont();

    const loadIncomeRange = async () => {
      try {
        const incomeData = await fetchIncomeRange();
        setIncomeRange(incomeData);
        const arr_44 = [];
        incomeData.map((inc) => {
          arr_44.push({ label: inc.lable, value: inc.lable });
        });
        setAnnualIncomes(arr_44);
      } catch (err) {
        console.error("Error loading income:", err);
      }
    };

    loadIncomeRange();

    const loadProfiles = async () => {
      try {
        const profilesData = await fetchProfiles();
        setProfiles(profilesData);
      } catch (error) {
        console.error("Error loading profiles:", error);
      }
    };

    loadProfiles();

    //income range end
  }, [userEdit]);

  const loadState = async (country) => {
    try {
      const stateData = await fetchStateMultiple(country);
      setStateList(stateData);
      const _stateNames = [];

      stateData.map((st) => {
        _stateNames.push({ label: st.state, value: st.state });
      });
      setStates(_stateNames);
    } catch (err) {
      console.error("Error loading state:", err);
    }
  };

  const loadCity = async (country, state) => {
    try {
      const cityData = await fetchCityMultiple(country, state);
      setCityList(cityData);
      const cityNames = cityData.map((cit) => {
        return { label: cit.city, value: cit.city };
      });
      setCities(cityNames);
    } catch (err) {
      console.error("Error loading city:", err);
    }
  };

  useEffect(() => {
    const loadReligion = async () => {
      try {
        const religionData = await fetchReligion();
        setReligionList(religionData);
        const arr_1 = [];
        religionData.map((rel) => {
          arr_1.push({ label: rel.name, value: rel.name });
        });
        SetReligions(arr_1);
      } catch (error) {
        console.error("Error loading religion:", error);
      }
    };

    loadReligion();
  }, []);

  const handleChange = (SpritiualPath) => {
    setSpritiualPath(SpritiualPath);
  };

  const handleChangeReligion = (Rel) => {
    SetReligion(Rel);
  };

  const handleChangeHobbies = (hobby) =>{
    setUserHobbies(hobby);
  }


  const handleChangeAnnualIncome = (income) =>{
    setAnnualIncome(income);
  }

  const handleChangeCountry = (coun) => {
    setCountry(coun);
    setStates([]);
    setState("");
    setCities([]);
    setCity("");
    loadState(coun);
  };

  const handleChangeState = (stat) => {
    loadState(country);
    setState(stat);
    setCities([]);
    setCity("");
    loadCity(country, stat);
  };

  const handleChangeCity = (cit) => {
    setCity(cit);
  };

  const handleChangeMotherTongue = (tongue) => {
    setMotherTongue(tongue);
  };

  const handleChangeMarital = (Marital) => {
    setMarital(Marital);
  };

  const handleChangeAge = (e, input) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const age = parseInt(value, 10);

      if (input == "start") {
        SetStart_age(value);
      } else {
        SetEnd_age(value);
      }
    }
  };

  function handelFoucs(id) {
    document.getElementById(id).classList.add("drop_bx_active");
  }

  function handelFoucsout(id) {
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        document.getElementById(id).classList.remove("drop_bx_active");
      }, 300);
    }
  }

  function handleScrollsPartner() {
    window.scrollTo({
      top: 1100, 
      behavior: 'smooth' 
    });
    handlePartnerEdit();
  }

  function handleScrollsProfile() {
    window.scrollTo({
      top: 300, 
      behavior: 'smooth' 
    });
    handleUserEdit();
  }

  

  return (
    <>
      <section className="w70 m_auto myprofile">
        <div className="head w100">
          <h1>
            {userDetails.first_name} {userDetails.last_name}
          </h1>
          <span>({userDetails.uniquecode})</span>
        </div>
        <div className="basic_profile fcs-display w100">
          <aside>
          <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      name="photo"
                    />
            <img src={userDetails.profile_pic} alt="" />
          </aside>
          <div className="right_side">
            <div className="top_basic w100 fcs-display">
              <div className="left_b">
                <div className="card">
                  <label>Age/Height:</label>
                  <p>
                    {calculateAge(userDetails.d_o_b)}/{userDetails.height}
                  </p>
                </div>
                <div className="card">
                  <label>Matrital Status:</label>
                  <p>{userDetails.marital_status}</p>
                </div>
                <div className="card">
                  <label>Posted by:</label>
                  <p>{userDetails.profile_type}</p>
                </div>
              </div>
              <div className="right_b">
                <div className="card">
                  <label>Spritiual Path:</label>
                  <p>{userDetails.community}</p>
                </div>
                <div className="card">
                  <label>Religion:</label>
                  <p>{userDetails.religion}</p>
                </div>
                <div className="card">
                  <label>Mother Tongue:</label>
                  <p>{userDetails.mother_tongue}</p>
                </div>
              </div>
            </div>
            <div className="bottom_basic w100">
              <h3 className="color_text">Connect With Matching Profile</h3>
              <div className="cards w100 fcs-display">
                <li onClick={handleScrollsPartner}>Partner Preference</li>
                <li onClick={()=>{navigate('/accepted')}}>Accepted Requests</li>
                <li onClick={()=>{navigate('/newmatches')}}>New Matches</li>
                <li onClick={()=>{navigate('/myphoto')}}>Add Photo</li>
                <li onClick={()=>{navigate('/received')}}>Received Requests</li>
                <li onClick={()=>{navigate('/mymatches')}}>Suggested Matches</li>
                <li onClick={handleScrollsProfile}>Edit Your Profile</li>
                <li onClick={()=>{navigate('/sent')}}>Sent Requests</li>
                <li onClick={()=>{navigate('/nearme')}}>Search Matches</li>
              </div>
            </div>
          </div>
        </div>

        <div className="about_yourself">
          <form action="" method="post" onSubmit={UpdateUserDetails}>

          <div className="head w100" style={{ marginBottom: "20px" }}>
              <h1>All Details</h1>

              <button type="button" id="user_edit" onClick={handleUserEdit}>
              Edit <i className="bi bi-pencil-square"></i>
            </button>

            <button type="submit" id="user_update">
              Update <i className="bi bi-save"></i>
            </button>
            <button type="button" id="userLoad" disabled>
              Updating.. <i className="bi bi-save"></i>
            </button>
            </div>
            
            <div id="userDetails_set">
              <div className="nomal_det">
                <h4 className="color_text fcs-display border_text">
                  <span>
                    Personality, Family Details, Career, Partner Expectations
                    etc.
                  </span>{" "}
                </h4>
                <p>{userDetails.about ? userDetails.about : ''}</p>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Spritual Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Spritual Path</label>
                      <p>: { userDetails.community ? userDetails.community : ''}</p>
                    </div>

                    <div className="card">
                      <label>Following the path since birth</label>
                      <p>: { userDetails.path_since ? userDetails.path_since : ''}</p>
                    </div>

                    <div className="card">
                      <label>Initiated</label>
                      <p>: {userDetails.path_initiated ? userDetails.path_initiated:''}</p>
                    </div>
                    <div className="card">
                      <label>Official Sevadar</label>
                      <p>: {userDetails.official_sevadar ? userDetails.official_sevadar:''}</p>
                    </div>
                  
                  
                  
                  </div>

                  <div className="right_b">
                    <div className="card">
                      <label>Religion</label>
                      <p>: { userDetails.religion ? userDetails.religion : ''}</p>
                    </div>

                    <div className="card">
                      <label>Mother Tongue</label>
                      <p>: { userDetails.mother_tongue ? userDetails.mother_tongue : ''}</p>
                    </div>

                 
                  
                  
                  </div>
                
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Basics & Lifestyle</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Age</label>
                      <p>: { userDetails.d_o_b ? calculateAge(userDetails.d_o_b) : ''}</p>
                    </div>
                    <div className="card">
                      <label>Date of Birth</label>
                      <p>:{userDetails.d_o_b ? userDetails.d_o_b:''}</p>
                    </div>
                    <div className="card">
                      <label>Marital Status</label>
                      <p>: {userDetails.marital_status ? userDetails.marital_status : ''}</p>
                    </div>
                    <div className="card">
                      <label>Height</label>
                      <p>: {userDetails.height ? userDetails.height :'' }</p>
                    </div>
                    <div className="card">
                      <label>Grew up in</label>
                      <p>: {userDetails.grew_up_in ? userDetails.grew_up_in : ''}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Diet</label>
                      <p>: {userDetails.diet ? userDetails.diet :''}</p>
                    </div>
                    <div className="card">
                      <label>Personal Values</label>
                      <p>: {userDetails.personal_values ? userDetails.personal_values:''}</p>
                    </div>
                    <div className="card">
                      <label>Alcohol</label>
                      <p>: {userDetails.alcohol ? userDetails.alcohol : ''}</p>
                    </div>
                    <div className="card">
                      <label>Blood Group</label>
                      <p>: {userDetails.blood_group ? userDetails.blood_group :''}</p>
                    </div>
                    <div className="card">
                      <label>Health Information</label>
                      <p>: {userDetails.health_information ? userDetails.health_information :''}</p>
                    </div>
                    <div className="card">
                      <label>Disability</label>
                      <p>: {userDetails.disability ? userDetails.disability:''}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Family details</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Mother's Occupation</label>
                      <p>: {userDetails.mother_occupation ? userDetails.mother_occupation  : ''}</p>
                    </div>
                    <div className="card">
                      <label>Father's Occupation</label>
                      <p>: {userDetails.father_ocupation ? userDetails.father_ocupation:''}</p>
                    </div>
                    <div className="card">
                      <label>Family Status</label>
                      <p>: {userDetails.famliy_status ? userDetails.famliy_status:''}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Having kids</label>
                      <p>: {userDetails.kids ? userDetails.kids :''}</p>
                    </div>
                    <div className="card">
                      <label>No. of Sisters</label>
                      <p>: {userDetails.no_of_sisters ? userDetails.no_of_sisters:''}</p>
                    </div>
                    <div className="card">
                      <label>No. of Brothers</label>
                      <p>: {userDetails.no_of_brothers ? userDetails.no_of_brothers:''}</p>
                    </div>
                    <div className="card">
                      <label>Family Income</label>
                      <p>: {userDetails.family_financial_status ? userDetails.family_financial_status:''}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box  eduction_bx_par">
                <h4 className="color_text fcs-display border_text">
                  <span>Education & Career</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Highest Qualification</label>
                      <p>: {userDetails.qualification ? userDetails.qualification :''}</p>
                    </div>
                    <div className="card">
                      <label>College(s) Attended</label>
                      <p>: {userDetails.collage ? userDetails.collage:''}</p>
                    </div>
                    <div className="card">
                      <label>Annual Income</label>
                      <p>: {userDetails.income ? userDetails.income :''}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Working With</label>
                      <p>: {userDetails.working_with ? userDetails.working_with:''}</p>
                    </div>
                    <div className="card">
                      <label>Working As</label>
                      <p>: {userDetails.designation ? userDetails.designation:''}</p>
                    </div>
                    <div className="card">
                      <label>Employer Name</label>
                      <p>: {userDetails.company ? userDetails.company:''}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Location of Groom</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Current Residence</label>
                      <p>
                        : {userDetails.city ? userDetails.city:''}, {userDetails.country?userDetails.country:''}
                      </p>
                    </div>
                    <div className="card">
                      <label>State Of Residence</label>
                      <p>: {userDetails.state ? userDetails.state:''}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Residency Status</label>
                      <p>: {userDetails.residency_status ? userDetails.residency_status:''}</p>
                    </div>
                    <div className="card">
                      <label>Zip / Pin code</label>
                      <p>: {userDetails.zipcode ? userDetails.zipcode:''}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Hobbies and Interests</span>{" "}
                </h4>
                <div className="small_cards w100 fcs-display">
                  <ul style={{display:"flex"}}>
                    {userDetails.hobies_intrest
                      ? userDetails.hobies_intrest
                          .split(",")
                          .map((hobby, index) => <li key={index}>{hobby}</li>)
                      : null}
                  </ul>
                </div>
              </div>
            </div>

            <div id="userDetails_edit" style={{ display: "none" }}>
              <div className="nomal_det">
                  <h4 className="color_text fcs-display border_text">
                    <span>
                      Personality, Family Details, Career, Partner Expectations
                      etc.
                    </span>{" "}
                  </h4>
                  <textarea name="" id="userAbout" placeholder="Enter About Yourself..." value={about} onChange={(e) => setAbout(e.target.value)}></textarea>
                </div>

                <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Spritual Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Spritual Path</label>

                      <div className="filter_card heightCard" style={{zIndex: 9999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userSpritual}
                          onFocus={() => handelFoucs("sel_userSpritual")}
                          onBlur={() => handelFoucsout("sel_userSpritual")}
                        />

                        <div className="drop_bx" id="sel_userSpritual">
                        {SpritualList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserSpritual(el.name);
                              }}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <label>Following the path since birth</label>
                      <div className="filter_card heightCard" style={{zIndex: 999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={Path_since}
                          onFocus={() => handelFoucs("sel_path_since")}
                          onBlur={() => handelFoucsout("sel_path_since")}
                        />

                        <div className="drop_bx" id="sel_path_since">
                          <li onClick={() => {
                                setPath_since('Yes');
                              }}>Yes</li>
                          <li onClick={() => {
                                setPath_since('No');
                              }}>No</li>
                         
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <label>Initiated</label>
                      <div className="filter_card heightCard" style={{zIndex: 99999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={Path_initiated}
                          onFocus={() => handelFoucs("sel_path_initiated")}
                          onBlur={() => handelFoucsout("sel_path_initiated")}
                        />

                        <div className="drop_bx" id="sel_path_initiated">
                          <li onClick={() => {
                                setPath_initiated('Yes');
                              }}>Yes</li>
                          <li onClick={() => {
                                setPath_initiated('No');
                              }}>No</li>
                         
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <label>Official Sevadar</label>
                      <div className="filter_card heightCard" style={{zIndex: 9999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={Official_sevadar}
                          onFocus={() => handelFoucs("sel_Official_sevadar")}
                          onBlur={() => handelFoucsout("sel_Official_sevadar")}
                        />

                        <div className="drop_bx" id="sel_Official_sevadar">
                          <li onClick={() => {
                                setOfficial_sevadar('Yes');
                              }}>Yes</li>
                          <li onClick={() => {
                                setOfficial_sevadar('No');
                              }}>No</li>
                         
                        </div>
                      </div>
                    </div>
                  
                  
                  
                  </div>

                  <div className="right_b">
                    <div className="card">
                      <label>Religion</label>
                      <div className="filter_card heightCard" style={{zIndex: 99999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userReligion}
                          onFocus={() => handelFoucs("sel_userReligion")}
                          onBlur={() => handelFoucsout("sel_userReligion")}
                        />

                        <div className="drop_bx" id="sel_userReligion">
                        {ReligionList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserReligion(el.name);
                              }}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <label>Mother Tongue</label>
                      <div className="filter_card heightCard" style={{zIndex: 9999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userMotherTng}
                          onFocus={() => handelFoucs("sel_MotherTng")}
                          onBlur={() => handelFoucsout("sel_MotherTng")}
                        />

                        <div className="drop_bx" id="sel_MotherTng">
                        {MotherTngList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserMotherTng(el.name);
                              }}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                 
                  
                  
                  </div>
                
                </div>
              </div>
              
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Basics & Lifestyle</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    
                    <div className="card noraml_in">
                      <label>Date of Birth</label>
                      <input
                        type="date"
                        placeholder="Date Of Birth"
                        name="dateOfBirth"
                        value={userDOB}
                        onChange={(e) => {setUserDOB(e.target.value);setUserAge(calculateAge(e.target.value))}}
                      />
                    </div>

                    <div className="card noraml_in">
                      <label>Age</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Age"
                        value={userAge}
                        onChange={(e) => {setUserAge(e.target.value);setUserDOB("")}}
                      />
                    </div>

                    <div className="card">
                      <label>Marital Status</label>
                      <div className="filter_card heightCard" style={{zIndex: 999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={maritalUserStatus}
                          onFocus={() => handelFoucs("sel1")}
                          onBlur={() => handelFoucsout("sel1")}
                        />

                        <div className="drop_bx" id="sel1">
                          {MaritalList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserMaritalStatus(el.name);
                              }}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <label>Height</label>
                      <div className="filter_card heightCard" style={{zIndex: 99999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userHeight}
                          onFocus={() => handelFoucs("sel2")}
                          onBlur={() => handelFoucsout("sel2")}
                        />

                        <div className="drop_bx" id="sel2">
                          {height.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserHeight(el.lable);
                                setUserHeightInch(el.inch);
                              }}
                            >
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card noraml_in">
                      <label>Grew Up In</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Grew Up In"
                        value={userGrewUp}
                        onChange={(e) => {setUserGrewUp(e.target.value)}}
                      />
                    </div>
                    
                  </div>
                  <div className="right_b">

                 
                    
                    <div className="card">
                      <label>Diet</label>
                     

                    <div className="filter_card heightCard" style={{zIndex: 999999999}}> 
                        <input
                          type="text"
                          placeholder="Select"
                          value={userDiet}
                          onFocus={() => handelFoucs("sel9")}
                          onBlur={() => handelFoucsout("sel9")}
                        />

                        <div className="drop_bx" id="sel9">
                          <li onClick={() => {setUserDiet("Vegetarian");}}>Vegetarian</li>
                          <li onClick={() => {setUserDiet("Non-vegetarian");}}>Non-vegetarian</li>
                          <li onClick={() => {setUserDiet("Eggetarian");}}>Eggetarian</li>
                          <li onClick={() => {setUserDiet("Occasionally Non-vegetarian");}}>Occasionally Non-vegetarian</li>
                          
                        </div>
                      </div>
                     
                    </div>

                  
                    <div className="card noraml_in">
                      <label>Personal Values</label>
                      <input
                        type="text"
                        placeholder="Personal Values"
                        name=""
                        value={userPersonalValues}
                        onChange={(e) => setUserPersonalValues(e.target.value)}
                      />

                    
                    </div>
                    <div className="card">
                      <label>Alcohol</label>
                      

                  <div className="filter_card heightCard" style={{zIndex: 99999999}}> 
                        <input
                          type="text"
                          placeholder="Select"
                          value={userSunSign}
                          onFocus={() => handelFoucs("sel10")}
                          onBlur={() => handelFoucsout("sel10")}
                        />

                        <div className="drop_bx" id="sel10">
                          <li onClick={() => {setUserSunSign("Yes");}}>Yes</li>
                          <li onClick={() => {setUserSunSign("No");}}>No</li>
                          <li onClick={() => {setUserSunSign("Occasionally");}}>Occasionally</li>
                        
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <label>Blood Group</label>

                      <div className="filter_card heightCard" style={{zIndex: 9999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userBloodGroup}
                          onFocus={() => handelFoucs("sel8")}
                          onBlur={() => handelFoucsout("sel8")}
                        />

                        <div className="drop_bx" id="sel8">
                          <li onClick={() => {setUserBloodGroup("O negative");}}>O negative</li>
                          <li onClick={() => {setUserBloodGroup("O positive");}}>O positive</li>
                          <li onClick={() => {setUserBloodGroup("A negative");}}>A negative</li>
                          <li onClick={() => {setUserBloodGroup("A positive");}}>A positive</li>
                          <li onClick={() => {setUserBloodGroup("B negative");}}>B negative</li>
                          <li onClick={() => {setUserBloodGroup("B positive");}}>B positive</li>
                          <li onClick={() => {setUserBloodGroup("AB negative");}}>AB negative</li>
                          <li onClick={() => {setUserBloodGroup("AB positive");}}>AB positive</li>

                        </div>
                      </div>
                     
                    </div>
                    <div className="card noraml_in">
                      <label>Health Information</label>
                      <input
                        type="text"
                        placeholder="Health Information"
                        name=""
                        value={userHealthInfo}
                        onChange={(e) => setUserHealthInfo(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Disability</label>
                      <input
                        type="text"
                        placeholder="Disability"
                        name=""
                        value={userDisablity}
                        onChange={(e) => setUserDisablity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Family details</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card noraml_in">
                      <label>Mother's Occupation</label>
                      <input
                        type="text"
                        placeholder="Mother Occupation"
                        name=""
                        value={userMotherDetails}
                        onChange={(e) => setUserMotherDetails(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Father's Occupation</label>
                      <input
                        type="text"
                        placeholder="Father Occupation"
                        name=""
                        value={userFatherDetails}
                        onChange={(e) => setUserFatherDetails(e.target.value)}
                      />
                    </div>
                    <div className="card">
                      <label>Family Status</label>
                     
                    <div className="filter_card heightCard" style={{zIndex: 999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userFamilyLocation}
                          onFocus={() => handelFoucs("sel11")}
                          onBlur={() => handelFoucsout("sel11")}
                        />

                      <div className="drop_bx" id="sel11">
                          <li onClick={() => {setUserFamilyLocation("Both parents are alive");}}>Both parents are alive</li>
                          <li onClick={() => {setUserFamilyLocation("Mother passed away");}}>Mother passed away</li>
                          <li onClick={() => {setUserFamilyLocation("Father passed away");}}>Father passed away</li>
                          <li onClick={() => {setUserFamilyLocation("Both parents passed away");}}>Both parents passed away</li>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_b">
                  <div className="card">
                  <label>Having kids</label>
                  <div className="filter_card heightCard" style={{zIndex: 999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={kids}
                          onFocus={() => handelFoucs("sel_kids")}
                          onBlur={() => handelFoucsout("sel_kids")}
                        />

                      <div className="drop_bx" id="sel_kids">
                          <li onClick={() => {setKids("Yes");}}>Yes</li>
                          <li onClick={() => {setKids("No");}}>No</li>
                          <li onClick={() => {setKids("Yes, but not with me");}}>Yes, but not with me</li>
                         
                        
                        </div>
                    </div>
                  </div>
                 
                    <div className="card noraml_in">
                      <label>No. of Sisters</label>
                      <input
                        type="text"
                        placeholder="No. of Sisters"
                        name=""
                        value={userNoOfSis}
                        onChange={(e) => setUserNoOfSis(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>No. of Brothers</label>
                      <input
                        type="text"
                        placeholder="No. of Brothers"
                        name=""
                        value={userNoOfBro}
                        onChange={(e) => setUserNoOfBro(e.target.value)}
                      />
                    </div>
                    <div className="card">
                      <label>Family Income</label>
                   

                      <div className="filter_card heightCard" style={{zIndex: 999999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userFinancialStatus}
                          onFocus={() => handelFoucs("sel12")}
                          onBlur={() => handelFoucsout("sel12")}
                        />

                        <div className="drop_bx" id="sel12">
                          {incomeRange.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setFinancialStatus(el.lable);
                              }}
                            >
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Education & Career</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Highest Qualification</label>
                      <div className="filter_card heightCard" style={{zIndex: 99999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={UserQualification}
                          onFocus={() => handelFoucs("sel6")}
                          onBlur={() => handelFoucsout("sel6")}
                        />

                        <div className="drop_bx" id="sel6">
                          {highQualificaion.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserQualification(el.name);
                              }}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card noraml_in">
                      <label>College(s) Attended</label>
                      <input
                        type="text"
                        placeholder="College(s) Attended"
                        name=""
                        value={userCollage}
                        onChange={(e) => setUserCollage(e.target.value)}
                      />
                    </div>
                    <div className="card">
                      <label>Annual Income</label>
                      <div className="filter_card heightCard" style={{zIndex: 9999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userIncome}
                          onFocus={() => handelFoucs("sel7")}
                          onBlur={() => handelFoucsout("sel7")}
                        />

                        <div className="drop_bx" id="sel7">
                          {incomeRange.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserIncome(el.lable);
                              }}
                            >
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card noraml_in">
                      <label>Working With</label>
                      <input
                        type="text"
                        placeholder="Working With"
                        name=""
                        value={userWorkingWith}
                        onChange={(e) => setUserWorkingWith(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Working As</label>
                      <input
                        type="text"
                        placeholder="Deignation"
                        name=""
                        value={userDesignation}
                        onChange={(e) => setUserDesignation(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Employer Name</label>
                      <input
                        type="text"
                        placeholder="Company"
                        name=""
                        value={UserCompany}
                        onChange={(e) => setUserCompany(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Location of Groom</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card noraml_in">
                      <label>Current Residence</label>
                      <input
                        type="text"
                        placeholder="Current Residence"
                        name=""
                        value={userCurrentResidence}
                        onChange={(e) => setUserCurrentResidence(e.target.value)}
                      />
                    </div>
                    <div className="card">
                      <label>Country</label>
                      <div className="filter_card heightCard" style={{zIndex: 999999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userCountry}
                          onFocus={() => handelFoucs("sel3")}
                          onBlur={() => handelFoucsout("sel3")}
                        />

                        <div className="drop_bx" id="sel3">
                          {CountryList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserCountry(el.country);
                                changeCountry(el.country);
                              }}
                            >
                              {el.country}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <label>State Of Residence</label>
                      <div className="filter_card heightCard" style={{zIndex: 99999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userState}
                          onFocus={() => handelFoucs("sel4")}
                          onBlur={() => handelFoucsout("sel4")}
                        />

                        <div className="drop_bx" id="sel4">
                          {StateList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserState(el.state);
                                changeState(userCountry,el.state);
                              }}
                            >
                              {el.state}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                  
                   
                  </div>
                  <div className="right_b">
                  <div className="card">
                      <label>City</label>
                      <div className="filter_card heightCard" style={{zIndex: 9999}}>
                        <input
                          type="text"
                          placeholder="Select"
                          value={userCity}
                          onFocus={() => handelFoucs("sel5")}
                          onBlur={() => handelFoucsout("sel5")}
                        />

                        <div className="drop_bx" id="sel5">
                          {CityList.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setUserCity(el.city);
                              }}
                            >
                              {el.city}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card noraml_in">
                      <label>Residency Status</label>
                      <input
                        type="text"
                        placeholder="Residency Status"
                        name=""
                        value={UserResidencyStatus}
                        onChange={(e) => setUserResidencyStatus(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Zip / Pin code</label>
                      <input
                        type="text"
                        placeholder="Zip / Pin code"
                        name=""
                        value={userZipcode}
                        onChange={(e) => setUserZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Hobbies and Interests</span>{" "}
                </h4>
                <Select
                        className="w100 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={UserHobbies}
                        onChange={handleChangeHobbies}
                        options={HobbiesListMultiple}
                      />
              </div>
            </div>
          </form>

          <form action="" method="post" onSubmit={updatePartnerEdit}>
            <div className="head w100" style={{ marginTop: "20px" }}>
              <h1>Partner Preferences</h1>

              <button
                type="button"
                id="partner_preference_edit"
                onClick={handlePartnerEdit}
              >
                Edit <i className="bi bi-pencil-square"></i>
              </button>

              <button type="submit" id="partner_preference_update">
                Update <i className="bi bi-save"></i>
              </button>
              <button type="button" id="partnerLoad" disabled>
                Updating.. <i className="bi bi-save"></i>
              </button>
            </div>

            <div id="partnerP_set">
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Basic Info</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Age</label>
                      <p>
                        :{" "}
                        {partnerPreference && partnerPreference.start_age
                          ? partnerPreference.start_age
                          : 0}{" "}
                        to{" "}
                        {partnerPreference && partnerPreference.end_age
                          ? partnerPreference.end_age
                          : "_"}
                      </p>
                    </div>
                    <div className="card">
                      <label>Height</label>
                      <p>
                        :{" "}
                        {partnerPreference &&
                        partnerPreference.start_height_lable
                          ? partnerPreference.start_height_lable
                          : 0}{" "}
                        (
                        {convertInchesToCentimeters(
                          partnerPreference.start_height_inch
                        )}{" "}
                        cm) to{" "}
                        {partnerPreference && partnerPreference.end_height_lable
                          ? partnerPreference.end_height_lable
                          : 0}{" "}
                        (
                        {convertInchesToCentimeters(
                          partnerPreference.end_height_inch
                        )}{" "}
                        cm)
                      </p>
                    </div>
                    <div className="card">
                      <label>Religion / Spritiual Path</label>
                      <p>
                        : {partnerPreference.religion}/{" "}
                        {partnerPreference.community}
                      </p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Mother tongue:</label>
                      <p>: {partnerPreference.mother_tongue}</p>
                    </div>
                    <div className="card">
                      <label>Marital status:</label>
                      <p>: {partnerPreference.marital_status}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Location Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Country living in</label>
                      <p>: {partnerPreference.country}</p>
                    </div>
                    <div className="card">
                      <label>State living in:</label>
                      <p>: {partnerPreference.state}</p>
                    </div>
                    <div className="card">
                      <label>City / District:</label>
                      <p>
                        : {partnerPreference.city} /{" "}
                        {partnerPreference.district}
                      </p>
                    </div>
                  </div>
                  <div className="right_b"></div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Education & Career</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Education</label>
                      <p>: {partnerPreference.education}</p>
                    </div>
                    <div className="card">
                      <label>Working with</label>
                      <p>: {partnerPreference.working_with}</p>
                    </div>
                    <div className="card">
                      <label>Profession area</label>
                      <p>: {partnerPreference.profession_area}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Working as</label>
                      <p>: {partnerPreference.designation}</p>
                    </div>
                    <div className="card">
                      <label>Annual income</label>
                      <p>: {partnerPreference.annual_income}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Other Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Profile created by</label>
                      <p>: {partnerPreference.profile_created_by}</p>
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Diet</label>
                      <p>: {partnerPreference.diet}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="partnerP_edit" style={{ display: "none" }}>
              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Basic Info</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Age</label>
                      <div className="inputs fcs-display editAge">
                        <input
                          type="text"
                          placeholder="Start Age"
                          max={50}
                          min={18}
                          onChange={(e) => handleChangeAge(e, "start")}
                          value={Start_age}
                        />
                        <input
                          type="text"
                          placeholder="End Age"
                          onChange={(e) => handleChangeAge(e, "end")}
                          value={End_age}
                          max={50}
                          min={18}
                        />
                      </div>
                    </div>
                    <div className="card">
                      <label>Height</label>
                      <div className="filter_card heightCard">
                        <input
                          type="text"
                          placeholder="Start Height"
                          value={startHeight}
                          onFocus={() => handelFoucs("sheight_for_drop_bx")}
                          onBlur={() => handelFoucsout("sheight_for_drop_bx")}
                        />

                        <div className="drop_bx" id="sheight_for_drop_bx">
                          {height.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setStartHeight(el.lable);
                                setStartHeightInct(el.inch);
                              }}
                            >
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div>

                      <div className="filter_card heightCard">
                        <input
                          type="text"
                          placeholder="End Height"
                          value={endHeight}
                          onFocus={() => handelFoucs("eheight_drop_bx")}
                          onBlur={() => handelFoucsout("eheight_drop_bx")}
                        />

                        <div className="drop_bx" id="eheight_drop_bx">
                          {height.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => {
                                setEndHeight(el.lable);
                                setEndHeightInct(el.inch);
                              }}
                            >
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <label>Religion</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={Religion}
                        onChange={handleChangeReligion}
                        options={Religions}
                      />
                    </div>

                    <div className="card">
                      <label>Spritiual Path</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={SpritiualPath}
                        onChange={handleChange}
                        options={communityes}
                      />
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card">
                      <label>Mother tongue:</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={MotherTongue}
                        onChange={handleChangeMotherTongue}
                        options={MotherTongues}
                      />
                    </div>
                    <div className="card">
                      <label>Marital status:</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={Marital}
                        onChange={handleChangeMarital}
                        options={MaritalStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Location Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Country living in</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={country}
                        onChange={handleChangeCountry}
                        options={countries}
                      />
                    </div>
                    <div className="card">
                      <label>State living in:</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={state}
                        onChange={handleChangeState}
                        options={states}
                      />
                    </div>
                    <div className="card">
                      <label>City:</label>
                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={city}
                        onChange={handleChangeCity}
                        options={cities}
                      />
                    </div>

                    <div className="card noraml_in">
                      <label>District</label>
                      <input
                        type="text"
                        name="district"
                        id=""
                        placeholder="District"
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="right_b"></div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Education & Career</span>{" "}
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Education</label>

                      <div className="filter_card heightCard" style={{width: '68%' , marginRight: '6px'}}>
                        <input
                          type="text"
                          placeholder="Select Education"
                          value={qualification}
                          onFocus={() => handelFoucs("qual_drop_bx1")}
                          onBlur={() => handelFoucsout("qual_drop_bx1")}
                        />

                        <div className="drop_bx" id="qual_drop_bx1" style={{zIndex: 9999}}>
                          {highQualificaion.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => setQualification(el.name)}
                            >
                              {el.name}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card noraml_in">
                      <label>Working with</label>
                      <input
                        type="text"
                        name="working_with"
                        id=""
                        placeholder="Company"
                        value={workingWith}
                        onChange={(e) => SetWorkingWith(e.target.value)}
                      />
                    </div>
                    <div className="card noraml_in">
                      <label>Profession area</label>
                      <input
                        type="text"
                        name="profession_area"
                        id=""
                        placeholder="Profession area"
                        value={professionArea}
                        onChange={(e) => SetProfessionArea(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="right_b">
                    <div className="card noraml_in">
                      <label>Working as</label>
                      <input
                        type="text"
                        name="designation"
                        id=""
                        placeholder="Designation"
                        value={designation}
                        onChange={(e) => SetDesignation(e.target.value)}
                      />
                    </div>
                    <div className="card">
                      <label>Annual income</label>
                      {/* <div className="filter_card heightCard" style={{zIndex:99999999}}>
                        <input
                          type="text"
                          placeholder="Select Income"
                          value={income}
                          onFocus={() => handelFoucs("qual_drop_bx2")}
                          onBlur={() => handelFoucsout("qual_drop_bx2")}
                        />

                        <div className="drop_bx" id="qual_drop_bx2">
                          {incomeRange.map((el, i) => (
                            <li key={i} onClick={() => setIncome(el.lable)}>
                              {el.lable}
                            </li>
                          ))}
                        </div>
                      </div> */}

                      <Select
                        className="w70 multipleSelect"
                        style={{ zIndex: 9999, position: "relative" }}
                        isMulti
                        value={annualIncome}
                        onChange={handleChangeAnnualIncome}
                        options={annualIncomes}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="det_box">
                <h4 className="color_text fcs-display border_text">
                  <span>Other Details</span>
                </h4>
                <div className="cards w100 fcs-display">
                  <div className="left_b">
                    <div className="card">
                      <label>Profile created by</label>
                      <div className="filter_card heightCard" style={{width: '68%' , marginRight: '6px'}}>
                        <input
                          type="text"
                          placeholder="Select Profile"
                          value={profile}
                          onFocus={() => handelFoucs("qual_drop_bx3")}
                          onBlur={() => handelFoucsout("qual_drop_bx3")}
                        />

                        <div className="drop_bx" id="qual_drop_bx3">
                          {profiles.map((el, i) => (
                            <li
                              key={i}
                              onClick={() => setProfile(el.partner_pref_lable)}
                            >
                              {el.partner_pref_lable}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <label>Diet</label>
                     
                    <div className="filter_card heightCard" style={{width: '68%' , marginRight: '6px' , zIndex:9999999}}> 
                        <input
                          type="text"
                          placeholder="Select"
                          value={diet}
                          onFocus={() => handelFoucs("sel13")}
                          onBlur={() => handelFoucsout("sel13")}
                        />

                        <div className="drop_bx" id="sel13">
                          <li onClick={() => {setDiet("Vegetarian");}}>Vegetarian</li>
                          <li onClick={() => {setDiet("Non-vegetarian");}}>Non-vegetarian</li>
                          <li onClick={() => {setDiet("Eggetarian");}}>Eggetarian</li>
                          <li onClick={() => {setDiet("Occasionally Non-vegetarian");}}>Occasionally Non-vegetarian</li>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default My_profile;
