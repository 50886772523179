import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    const nav = document.getElementsByTagName('nav')[0];
    const logoImg = nav.getElementsByTagName('img')[0];
    nav.classList.add('active_nav');
    
    const handleScroll = () => {
      if (window.scrollY > 5) {
        if (location.pathname === '/') {
          logoImg.src = 'img/logo_wh.png';
          nav.classList.add('active_nav');
          nav.classList.add('active_nav1');
        } else {
          nav.classList.add('active_nav');
          logoImg.src = 'img/logo_wh.png';
        }
        
      } else {
        nav.classList.remove('active_nav');
        nav.classList.remove('active_nav1');
        if (location.pathname === '/') {
          logoImg.src = 'img/logo.png';
        } else {
          nav.classList.add('active_nav');
          logoImg.src = 'img/logo_wh.png';
        }
      }
    };

    handleScroll(); // Initial check on component mount

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (location.pathname === '/') {
        nav.classList.remove('active_nav');
        logoImg.src = 'img/logo.png';
      } else {
        nav.classList.add('active_nav');
        logoImg.src = 'img/logo_wh.png';
      }
    };
  }, [location.pathname]);

  return (
    <footer className="w100">
      <div className="w75 sub_footer fcs-display m_auto">
        <div className="foot_bx">
          <img src="/img/logo_wh.png" alt="Company Logo" />
          <p className="fw5">
          Satsangirishtey.com - India's first matrimonial website for "Satsangi Rishtey"
          Make your profile and search your life partner.
          </p>
        </div>
        <div className="foot_bx">
          <h5>Need Help?</h5>
          <ul>
            <li><Link to="#" onClick={() =>
              document
                .getElementsByClassName("container")[0]
                .classList.add("container_active")
            }>Member Login</Link></li>
            <li><Link to="#" onClick={() => {
                  document.getElementsByClassName(
                    "sign_up_steps"
                  )[0].style.zIndex = 9999999999;
                  document.getElementsByClassName(
                    "sign_up_steps"
                  )[0].style.opacity = 1;
                  document
                    .getElementsByClassName("container")[0]
                    .classList.remove("container_active");
                }} >Sign Up</Link></li>
            <li><Link to="/basic_search">Partner Search</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Company</h5>
          <ul>
            <li><Link to="/about-us">About Us</Link></li>
            {/* <li><Link to="/plan">Plan</Link></li> */}
            <li><Link to="/nri">VIP/NRI Shadi</Link></li>
            <li><Link to="#">Success Stories</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Privacy & You</h5>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-Of-use">Terms Of Use</Link></li>
          </ul>
        </div>
        <div className="foot_bx">
          <h5>Follow Us</h5>
          <div className="social_media">
            <Link to="https://www.facebook.com/satsangirishtey/"><i className="bi bi-facebook"></i></Link>
            <Link to="https://www.youtube.com/@satsangirishtey"><i className="bi bi-youtube"></i></Link>
            <Link to="https://www.instagram.com/satsangi_rishtey/"><i className="bi bi-instagram"></i></Link>
            <Link to="https://x.com/RishteySat65457"><i className="bi bi-twitter-x"></i></Link>
          </div>
          <ul>
            <li><a href="tel:0123456789"><i className="bi bi-phone-fill"></i> +91 8860020628</a></li>
            <li><a href="mailto:satsangirishtey@gmail.com"><i className="bi bi-envelope-fill"></i> satsangirishtey@gmail.com</a></li>
          </ul>
        </div>
      </div>
      <h6 className="m_auto center w75">Copyright 2023-2024 by satsangirishtey.com. All Rights Reserved. Powered by RSPH technology</h6>
    </footer>
  );
};

export default Footer;
